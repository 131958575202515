// eslint-disable-next-line no-restricted-syntax
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import IntegrationTestsApp from 'integration-tests/App';
import initSentry from 'sentry';
import initHotjar from 'hotjar';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

initSentry();
initHotjar();

// eslint-disable-next-line react/jsx-filename-extension
const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />));

const root = createRoot(document.getElementById('root'));

if (process.env.REACT_APP_E2E_TESTS === 'true') {
  root.render(React.createElement(IntegrationTestsApp));
} else {
  root.render(<RouterProvider router={router} />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

/* istanbul ignore file */
import React, { useEffect, useRef, useState } from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import useEmployeeService, {
  EMPLOYEE_TYPES_ALIAS,
  FINALIZE_KEY,
  FINALIZE_STATE,
  READ_EMPLOYEE_WORKING_STATUS,
  UPDATE_EMPLOYEE_ACCOUNTS_1C_ID,
  UPDATE_EMPLOYEE_ACCOUNTS_AZURE_ID,
  UPDATE_EMPLOYEE_CONTACT_INFORMATION,
  UPDATE_EMPLOYEE_FAMILY_INFORMATION,
  UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS,
  UPDATE_EMPLOYEE_MAIN_HR_INFORMATION,
  UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO,
  UPDATE_EMPLOYEE_MILITARY_INFO,
  UPDATE_EMPLOYEE_REQUISITES_INFORMATION,
} from 'erp/employee/employeeService';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import UpdateEmployeeBreadcrumbs from 'erp/employee/createupdate/Update/Breadcrumbs';
import FormSection from 'shared/layout/form/FormSection';
import { DateInput, GenderRadioButtonGroup, NameInput, PhoneInput, TextInput } from 'shared/uibuilder/form/input';
import FormRow from 'shared/layout/form/FormRow';
import { useSearchParameter } from 'shared/routing/helper';
import EmployeePhotoUploaderInput from 'artifact/shared/input/EmployeePhotoUploaderInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import AdditionalContactListInput from 'erp/shared/input/AdditionalContactListInput';
import MaritalStatusInput from 'erp/employee/shared/input/MaritalStatusInput';
import FamilyMemberListInput from 'erp/employee/shared/input/FamilyMemberListInput';
import PositionDropdown from 'erp/employee/shared/input/PositionDropdown';
import ResourcePoolDropdown from 'erp/employee/shared/input/ResourcePoolDropdown';
import MilitaryInput from 'erp/employee/shared/input/MilitaryInput';
import CopyableEmployeeAddressInput from 'erp/employee/shared/input/CopyableEmployeeAddressInput';
import FamilyMemberInput from 'erp/employee/shared/input/FamilyMemberInput';
import ChildrenInput from 'erp/employee/shared/input/ChildrenInput';
import AzureIdInput from 'erp/employee/shared/input/AzureIdInput';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import useAuthorization from 'shared/authorization/authorizationService';
import Prepopulation from 'erp/employee/prepopulation/Prepopulation';
import PrepopulationWrapper from 'erp/employee/prepopulation/PrepopulationWrapper';
import UpdateTitle from 'erp/employee/createupdate/Update/UpdateTitle';
import EmployeeWorkingStatusDropdown from 'erp/employee/shared/input/EmployeeWorkingStatusDropdown';
import EmployeeTypeDropDown from 'erp/employee/shared/input/EmployeeTypeDropdown';
import DocumentScanUploaderInput from 'artifact/shared/input/DocumentScanUploaderInput';
import { scrollToHash } from 'shared/uibuilder/domUtils';
import EmployeeWorkplaceDropdown from 'erp/employee/shared/input/EmployeeWorkplaceDropdown';
import { isPolishOffice } from 'erp/employee/office/officeService';
import CopyableLocationInput from 'erp/employee/shared/input/CopyableLocationInput';
import LocationUpdateDateInput from 'erp/employee/shared/input/LocationUpdateDateInput';
import IdentityDocumentListInput from '../../shared/input/IdentityDocumentInput/IdentityDocumentListInput';
import IdentityDocumentInput from '../../shared/input/IdentityDocumentInput/IdentityDocumentInput';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import PeopleManagementSpecifySection from 'erp/employee/shared/input/PeopleManagementSpecifySection';

const UpdateEmployeeProfile = () => {
  const state = useSearchParameter(FINALIZE_KEY);
  const { isGranted } = useAuthorization();
  const prepopulatedInputsRef = useRef(null);

  const { getSummaryUrl } = useEmployeeUrl();
  const { update, getForUpdate, getValidationSchema, isWorkplaceLocationNotRemote } = useEmployeeService();
  const id = useEmployeeId();
  const isVisibleOfficeLocation = (data: FormFieldsData) =>
    isWorkplaceLocationNotRemote(data?.extendedInformation?.hrInformation?.workplaceLocation);

  const [fieldsExceptions, setFieldsExceptions] = useState({});

  const isFinalizing = state === FINALIZE_STATE;

  const isNotPolishOffice = (formData: FormFieldsData) => {
    return !isPolishOffice(String(formData.officeId));
  };

  useEffect(() => {
    if (isFinalizing) {
      setFieldsExceptions({
        initializationState: 'FINALIZED',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const scrollToPrepopulatedInputs = () => {
    if (prepopulatedInputsRef.current) {
      scrollToHash(prepopulatedInputsRef.current);
    }
  };

  const isGrantedUpdateMainInfo = isGranted(UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO);
  const isGrantedUpdateContactInfo = isGranted(UPDATE_EMPLOYEE_CONTACT_INFORMATION);
  const isGrantedReadStatus = isGranted(READ_EMPLOYEE_WORKING_STATUS);

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getForUpdate}
      entityId={id}
      initialData={{
        maritalStatus: 'NOT_MARRIED',
      }}
      afterSubmit={{
        redirect: getSummaryUrl(),
        message: 'You are awesome! The employee has been successfully saved.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      fieldsExceptions={fieldsExceptions}
    >
      <Prepopulation>
        <UpdatePageLayout
          title={<UpdateTitle scrollToPrepopulatedInputs={scrollToPrepopulatedInputs} />}
          breadcrumbs={<UpdateEmployeeBreadcrumbs />}
          menu={<EmployeeMenu isEditForm />}
          entityHeader={<EmployeeEntityHeader />}
          shouldScrollToErrorAfterLoading={isFinalizing}
        >
          <FormSection
            title="Main information"
            hasAnchorLink
            isVisible={isGrantedUpdateMainInfo || isGrantedReadStatus}
          >
            <NameInput
              label="Local Name"
              source="nameLocal"
              middleNameLabel="Patronymic Name"
              isVisible={isGrantedUpdateMainInfo}
            />
            <NameInput
              label="Latin Name"
              source="nameEn"
              middleNameLabel="Middle Name"
              isVisible={isGrantedUpdateMainInfo}
            />
            <FormRow>
              <DateInput
                source="extendedInformation.dateOfBirth"
                label="Date of Birth"
                isVisible={isGrantedUpdateMainInfo}
              />
              <TextInput label="Syberry Email" source="workingEmail" isVisible={isGrantedUpdateMainInfo} />
              <EmployeeWorkingStatusDropdown
                isVisible={isGrantedReadStatus}
                disabled
                source="workingStatus"
                helpText="This field can be updated only within DaVinci processes"
              />
            </FormRow>
            <BigFormRow>
              <GenderRadioButtonGroup source="gender" label="Gender" isVisible={isGrantedUpdateMainInfo} />
            </BigFormRow>
            <BigFormRow>
              <EmployeePhotoUploaderInput
                label="Employee Photos"
                source="extendedInformation.photosIds"
                isVisible={isGrantedUpdateMainInfo}
              />
            </BigFormRow>
          </FormSection>

          <FormSection
            title="Identity documents"
            hasAnchorLink
            isVisible={isGranted(UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS)}
          >
            <BigFormRow>
              <IdentityDocumentListInput
                source="extendedInformation.identityDocuments"
                inputTemplate={IdentityDocumentInput}
                addText="Add document"
              />
            </BigFormRow>
          </FormSection>

          <FormSection title="Contact information" hasAnchorLink isVisible={isGrantedUpdateContactInfo}>
            <FormRow>
              <PhoneInput source="extendedInformation.personalPhoneNumber" />
              <TextInput source="extendedInformation.personalEmail" label="Personal Email Address" />
              <PhoneInput source="extendedInformation.otherPhoneNumber" />
            </FormRow>

            <AdditionalContactListInput source="extendedInformation.additionalContactInfo" />

            <span ref={prepopulatedInputsRef} className="invisible-anchor" />
            <MediumFormRow>
              <PrepopulationWrapper>
                <CopyableEmployeeAddressInput
                  source="extendedInformation.homeAddress"
                  copySource="extendedInformation.registrationAddress"
                  label="Current location address"
                  buttonText="Same as registration address"
                />
              </PrepopulationWrapper>
              <CopyableEmployeeAddressInput
                source="extendedInformation.registrationAddress"
                copySource="extendedInformation.homeAddress"
                label="Registration address"
                buttonText="Same as current location address"
              />
            </MediumFormRow>
            <MediumFormRow>
              <CopyableLocationInput
                source="extendedInformation.plannedMidTermLocation"
                copySource="extendedInformation.homeAddress"
                label="Planned mid-term location"
                buttonText="Same as current location address"
                tooltipMessage="Planned location for the nearest month"
              />
              <CopyableLocationInput
                source="extendedInformation.plannedPermLocation"
                copySource="extendedInformation.homeAddress"
                label="Planned permanent location"
                buttonText="Same as current location address"
                tooltipMessage="Planned location for the nearest year"
              />
            </MediumFormRow>
            <MediumFormRow>
              <LocationUpdateDateInput
                source="extendedInformation.locationUpdateDate"
                label="Date of location update"
              />
            </MediumFormRow>
          </FormSection>

          <FormSection title="Family" hasAnchorLink isVisible={isGranted(UPDATE_EMPLOYEE_FAMILY_INFORMATION)}>
            <FormRow>
              <MaritalStatusInput source="extendedInformation.maritalStatus" />
            </FormRow>
            <BigFormRow>
              <PrepopulationWrapper>
                <FamilyMemberListInput
                  source="extendedInformation.other"
                  inputTemplate={FamilyMemberInput}
                  addText="Add family member"
                />
              </PrepopulationWrapper>
            </BigFormRow>
            <BigFormRow>
              <PrepopulationWrapper>
                <ChildrenInput source="extendedInformation.children" />
              </PrepopulationWrapper>
            </BigFormRow>
          </FormSection>

          <FormSection title="HR information" hasAnchorLink isVisible={isGranted(UPDATE_EMPLOYEE_MAIN_HR_INFORMATION)}>
            <FormRow>
              <EmployeeTypeDropDown label="Type" source="employeeType" />
              <PositionDropdown source="positionId" label="Position" />
              <ResourcePoolDropdown source="resourcePoolId" label="Resource Pool" />
              <EmployeeWorkplaceDropdown source="extendedInformation.hrInformation.workplaceLocation" />
              <TextInput
                source="extendedInformation.hrInformation.roomNumber"
                label="Room Number"
                isVisible={isVisibleOfficeLocation}
              />
              <TextInput
                source="extendedInformation.hrInformation.deskLocation"
                label="Desk Location"
                isVisible={isVisibleOfficeLocation}
              />
              <TextInput source="extendedInformation.hrInformation.accessCardId" label="Access Card ID" />
            </FormRow>
            <PeopleManagementSpecifySection />
          </FormSection>

          <FormSection
            title="Military information"
            hasAnchorLink
            isVisible={isGranted(UPDATE_EMPLOYEE_MILITARY_INFO) && isNotPolishOffice}
          >
            <PrepopulationWrapper>
              <MilitaryInput source="extendedInformation.militaryRecord" />
            </PrepopulationWrapper>
          </FormSection>

          <FormSection
            title="Accounts"
            hasAnchorLink
            isVisible={isGranted(UPDATE_EMPLOYEE_ACCOUNTS_1C_ID) || isGranted(UPDATE_EMPLOYEE_ACCOUNTS_AZURE_ID)}
          >
            <FormRow>
              <TextInput
                source="extendedInformation.financialInformation.financialOneCId"
                label="1C ID"
                isVisible={isGranted(UPDATE_EMPLOYEE_ACCOUNTS_1C_ID)}
              />
              <AzureIdInput
                source="azureId"
                label="Azure ID"
                isVisible={isGranted(UPDATE_EMPLOYEE_ACCOUNTS_AZURE_ID)}
              />
            </FormRow>
          </FormSection>

          <FormSection
            title="Requisites"
            isVisible={employee =>
              employee.employeeType === EMPLOYEE_TYPES_ALIAS.CONTRACTOR &&
              isGranted(UPDATE_EMPLOYEE_REQUISITES_INFORMATION)
            }
          >
            <TextInput source="extendedInformation.requisitesInformation.requisites" label="Requisites" />
            <DocumentScanUploaderInput
              source="extendedInformation.requisitesInformation.scansIds"
              label="Document scans"
            />
          </FormSection>
        </UpdatePageLayout>
      </Prepopulation>
    </UpdateForm>
  );
};

export default UpdateEmployeeProfile;

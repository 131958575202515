/* istanbul ignore file */
import React from 'react';
import { styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { Placement } from '@popperjs/core/lib';
import MenuList, { MenuListProps } from '@mui/material/MenuList';
import { bindPopper, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { SxProps } from '@mui/system';

export const StyledMenuList = styled(
  React.forwardRef((props: MenuListProps, ref: any) => <MenuList ref={ref} {...props} />),
)(({ theme }: { theme: any }) => ({
  padding: '4px 0',
  '& .MuiMenuItem-root': {
    paddingTop: '9px',
    paddingBottom: '9px',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
      color: theme.vars?.palette.text.secondary,
      marginRight: theme.spacing(1.5),
    },
    '&:active, &.active': {
      backgroundColor: theme.vars?.palette.common.menuItemActive,
    },
  },
}));

const DropdownMenu = ({
  popupId,
  iconButton,
  children,
  mouseEvent,
  touchEvent,
  disablePortal = true,
  className,
  menuPlacement = 'bottom-start',
  sxList,
  sxPaper,
}: {
  popupId: string;
  iconButton: React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
  mouseEvent?: 'onClick' | 'onMouseDown' | 'onMouseUp' | 'onPointerDown' | 'onPointerUp' | false;
  touchEvent?: 'onTouchStart' | 'onTouchEnd' | false;
  disablePortal?: boolean;
  className?: string;
  menuPlacement?: Placement;
  sxList?: SxProps;
  sxPaper?: SxProps;
}) => {
  const popupState = usePopupState({ variant: 'popper', popupId });

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    popupState.close(event as any);
  };

  return (
    <>
      {React.cloneElement(iconButton, {
        ...iconButton.props,
        ...bindToggle(popupState),
        onClick: (e: any) => {
          if (iconButton.props.onClick) {
            iconButton.props.onClick(e);
          }
          bindToggle(popupState).onClick(e);
        },
        onTouchStart: (e: any) => {
          if (iconButton.props.onClick) {
            iconButton.props.onClick(e);
          }
          bindToggle(popupState).onTouchStart(e);
        },
      })}
      <Popper
        {...bindPopper(popupState)}
        placement={menuPlacement}
        transition
        disablePortal={disablePortal}
        sx={{ zIndex: 1200 }}
        className={className}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              elevation={3}
              sx={{
                maxHeight: 'calc(100vh - 150px)',
                borderRadius: '4px',
                overflow: 'initial',
                minWidth: '200px',
                ...sxPaper,
              }}
            >
              <ClickAwayListener onClickAway={handleClickAway} mouseEvent={mouseEvent} touchEvent={touchEvent}>
                <StyledMenuList sx={sxList}>
                  {React.Children.map(children, child =>
                    React.cloneElement(child, {
                      ...child.props,
                      onClick: () => {
                        popupState.close();
                        if (child.props.onClick) {
                          child.props.onClick();
                        }
                      },
                    }),
                  )}
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropdownMenu;

import { LoadingParams } from 'shared/uibuilder/list/ListContext';
import { usePeopleManagementApi } from 'api';
import useCrudService from 'shared/crud';
import { cloneDeep } from 'lodash';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';
import followUpValidation from './shared/buttons/FollowUpSummaryButton/followUpValidation';
import { DashboardEmployee } from './types';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';

export const RESOURCE_URL = '/employees/dashboard';

const useEmployeesDashboardService = () => {
  const { addMessage } = useMessageService();
  const { sendPostRequest, sendPatchRequest } = usePeopleManagementApi();
  const { searchAll } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: usePeopleManagementApi,
  });

  const getAllEmployessForDashboard = async (loadParams: LoadingParams) => {
    try {
      const searchParams = cloneDeep(loadParams);
      const searchString = searchParams.filter?.['specification:search']?.eq || '';

      if (searchString && searchParams.filter) {
        delete searchParams.filter?.['specification:search'];
        searchParams.filter.searchString = {
          ct: searchString,
        };
      }
      const res = await searchAll({
        ...(searchParams as any),
        filter: {
          ...searchParams.filter,
        },
      });
      return {
        result: res.map((item: DashboardEmployee) => ({
          ...item,
          retentionRiskFollowUps: item.retentionRiskFollowUps.sort(
            (a, b) => new Date(a.followUpDate).getTime() - new Date(b.followUpDate).getTime(),
          ),
          id: item?.retentionRiskId, // === retentionRiskId (Need for List)
        })) as DashboardEmployee[],
        totalPages: 1,
        totalElements: res.length,
      };
    } catch {
      addMessage(new ErrorMessage('Error fetching employess list.'));
      return {
        result: [],
        totalPages: 1,
        totalElements: 0,
      };
    }
  };

  const assigneEmployeeAction = async (riskId: string, data: FormFieldsData) => {
    const request = await sendPatchRequest(`/retention-risks/${riskId}/assign`, data);

    return request.json();
  };

  const createFollowUp = async (riskId: string, data: FormFieldsData) => {
    const request = await sendPostRequest(`/retention-risks/${riskId}/follow-up`, data);

    return request.json();
  };

  const followUpValidationShema = followUpValidation();
  const getFollowUpValidation = () => Promise.resolve(followUpValidationShema);

  const updateHealthcheck = async (riskId: string, data: FormFieldsData) => {
    const request = await sendPatchRequest(`/retention-risks/${riskId}/healthcheck`, data);

    return request.json();
  };

  const changeZonePriority = async (riskId: string, data: FormFieldsData) => {
    const request = await sendPatchRequest(`/retention-risks/${riskId}/priority`, data);

    return request.json();
  };

  const updateIssues = async (riskId: string, data: FormFieldsData) => {
    const request = await sendPostRequest(`/retention-risks/${riskId}/issues/submit`, data);

    return request.json();
  };

  return {
    getAllEmployessForDashboard,
    createFollowUp,
    getFollowUpValidation,
    assigneEmployeeAction,
    updateHealthcheck,
    changeZonePriority,
    updateIssues,
  };
};

export default useEmployeesDashboardService;

import React, { useEffect, useState } from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Box from 'uibuilder/Box';
import Add from '@mui/icons-material/Add';
import CheckboxLayout from 'uibuilder/layout/form/input/CheckboxLayout';
import Typography from 'uibuilder/Typography';
import { Button } from 'uibuilder/button';
import { RetentionRiskIssue } from '../../types';
import { TextInput } from 'shared/uibuilder/form/input';
import { RETENTION_RISK_ISSUE_STATUS } from '../../constants';

interface CallbackData {
  [key: string]: any;
}

interface IssuesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (issues: Partial<RetentionRiskIssue>[]) => void;
  predefinedIssues: RetentionRiskIssue[];
}

const IssuesEmployeeModal: React.FC<IssuesModalProps> = ({ isOpen, onClose, onSubmit, predefinedIssues }) => {
  const [issues, setIssues] = useState<Partial<RetentionRiskIssue>[]>([]);

  useEffect(() => {
    setIssues(predefinedIssues);
  }, [isOpen, predefinedIssues]);

  useEffect(() => {
    if (isOpen && issues.length === 0) {
      addNewIssue();
    }
  }, [isOpen, issues]);

  const addNewIssue = () => {
    setIssues(prev => [...prev, {}]);
  };

  const handleCheckboxChange = (itemIndex: number, isChecked: boolean) => {
    setIssues(prev =>
      prev.map((item, index) =>
        index === itemIndex
          ? {
              ...item,
              status: isChecked ? RETENTION_RISK_ISSUE_STATUS.CLOSED : RETENTION_RISK_ISSUE_STATUS.ACTIVE,
            }
          : item,
      ),
    );
  };

  const handleIssueChange = (itemIndex: number, value: string) => {
    setIssues(prev => prev.map((item, index) => (index === itemIndex ? { ...item, description: value } : item)));
  };

  const handleSubmit = () => {
    const nonEmptyIssues = issues.filter(element => element.id || element.description);
    setIssues(nonEmptyIssues);
    onSubmit(nonEmptyIssues);
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      onToggle={onClose}
      hasCancelButton
      buttons={
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      }
    >
      <Box mb={2}>
        {issues?.map((issue, index) => {
          const elementId = issue.id || `${index}`;
          const isClosed = issue.status === 'CLOSED';
          if (issue?.id) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} sx={{ '& .MuiFormControl-root.checkbox-form-group': { mb: 1 } }}>
                <CheckboxLayout
                  source={elementId}
                  label={
                    <Typography
                      style={{
                        textDecoration: isClosed ? 'line-through' : 'none',
                      }}
                    >
                      {issue.description}
                    </Typography>
                  }
                  value={isClosed}
                  onChangeCallback={(event: object) => {
                    const data = event as CallbackData;
                    const isChecked = !!data[elementId];
                    handleCheckboxChange(index, isChecked);
                  }}
                />
              </Box>
            );
          } else {
            return (
              <Box mb={1} key={elementId}>
                <TextInput
                  source={elementId}
                  label=""
                  placeholder="New issue"
                  onChangeCallback={(inputData: any) => {
                    const value = inputData[elementId];
                    handleIssueChange(index, value);
                  }}
                  value={issue.description}
                />
              </Box>
            );
          }
        })}
      </Box>

      <Button link title="Add issue" onClick={addNewIssue}>
        <Add />
        Add issue
      </Button>
    </ModalWindow>
  );
};

export default IssuesEmployeeModal;

import CreateCandidateOfferForm from 'erp/candidate/offercompensation/createupdate/Create/CreateCandidateOfferForm';
import UpdateCandidateOfferForm from 'erp/candidate/offercompensation/createupdate/Update/UpdateCandidateOfferForm';
import OfferTimelineRecordLayout from './OfferTimelineRecordLayout';
import { CANDIDATE_TIMELINE_RECORDS_ALIAS } from 'erp/candidate/Timeline/shared/input/CandidateTimelineRecordTypeDropdown';
import { RECRUITING_OFFER_CREATE, RECRUITING_OFFER_UPDATE } from 'erp/candidate/candidateService';

const useCandidateOfferItem = () => ({
  AddForm: CreateCandidateOfferForm,
  UpdateForm: UpdateCandidateOfferForm,
  isApplicable: (item: any) => item && (item.type === CANDIDATE_TIMELINE_RECORDS_ALIAS.OFFER || item.offerId),
  source: null,
  urlPostfix: 'offers',
  RecordLayout: OfferTimelineRecordLayout,
  name: 'Offer',
  permissionToCheck: [RECRUITING_OFFER_CREATE, RECRUITING_OFFER_UPDATE],
  hasFullData: true,
});

export default useCandidateOfferItem;

import React from 'react';
import List from 'shared/uibuilder/list/List';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import useEmployeesDashboardService from '../useEmployeesDashboardService';
import { EMPLOYEE_ZONES_ALIASES } from '../constants';
import EmployeesDashboardZone from './EmployeesDashboardZone/EmployeesDashboardZone';
import { EmployeeZoneAlias } from '../types';
import { Filters, FiltersLayout } from 'shared/uibuilder/list/filter';
import EmployeeDashboardSpecializationsFilter from './filter/EmployeeDashboardSpecializationsFilter';
import EmployeeByRetentionRiskStatusFilter from './filter/EmployeeByRetentionRiskStatusFilter';
import EmployeeFilter from 'erp/employee/shared/filter/EmployeeFilter';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAsyncValue from 'shared/asyncHelper';

const EmployeesDashboard = () => {
  const { getAllEmployessForDashboard } = useEmployeesDashboardService();
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(() => getUserAlias());

  const filters = (
    <Filters>
      <FiltersLayout
        quickFilters={[
          <QuickFilter
            label="Assigned to me"
            state={{ 'specification:employeeByRetentionRiskAssigneeAliasSpecification': { eq: userAlias } }}
          />,
        ]}
      >
        <EmployeeFilter source="specification:employeeByRetentionRiskAssigneeAliasSpecification" label="Assignee" />
        <EmployeeByRetentionRiskStatusFilter
          source="specification:employeeByRetentionRiskStatusSpecification"
          label="Risk status"
        />
        <EmployeeDashboardSpecializationsFilter source="specializationInfo.specialization" label="Specialization" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List
      key="employees-dashboard"
      getDataMethod={getAllEmployessForDashboard}
      defaultSortOrder={DESC}
      defaultSortField="createdAt"
      pageSize={100}
    >
      <ListPageLayout
        filter={filters}
        sxPaper={{ p: '10px !important' }}
        search={<SearchInput label="Employee Name or ID" />}
      >
        {Object.values(EMPLOYEE_ZONES_ALIASES)
          .toReversed()
          .map(zone => {
            return <EmployeesDashboardZone key={zone} zone={zone as EmployeeZoneAlias} />;
          })}
      </ListPageLayout>
    </List>
  );
};

export default EmployeesDashboard;

import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import IssuesEmployeeModal from './IssuesEmployeeModal';
import { EMPLOYEE_ZONES, EMPLOYEE_ZONES_ALIASES, RETENTION_RISK_ISSUE_STATUS, zoneStyles } from '../../constants';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '../../List/EmployeesDashboardZone/employeesDashboardZone.scss';
import { DashboardEmployee, RetentionRiskIssue } from '../../types';
import useEmployeesDashboardService from '../../useEmployeesDashboardService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';

const ChangeZoneButton = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTargetZone, setTargetZone] = useState<string | undefined>(undefined);
  const { data, setData } = useShowContext();
  const { retentionRiskId: riskId } = data.getData() as DashboardEmployee;
  const { changeZonePriority, updateIssues } = useEmployeesDashboardService();
  const { addMessage } = useMessageService();
  const currentZone = data?.currentZone as keyof typeof EMPLOYEE_ZONES;
  type ZoneType = keyof typeof EMPLOYEE_ZONES_ALIASES;

  const currentZoneFirstLetter = String(currentZone as ZoneType).charAt(0);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = async (issues: Partial<RetentionRiskIssue>[]) => {
    Promise.all([
      updateIssues(riskId, { issues }).catch(() => addMessage(new ErrorMessage('Update issue error'))),
      changeZonePriority(riskId, { targetPriority: isTargetZone }).catch(() =>
        addMessage(new ErrorMessage('Change zone priority error')),
      ),
    ])
      .then(([issuesResponse, priorityResponse]) => {
        setData!({
          ...data.getData(),
          retentionRiskIssues: issuesResponse.issues?.filter(
            (element: any) => element.status === RETENTION_RISK_ISSUE_STATUS.ACTIVE,
          ),
          retentionRiskPriority: priorityResponse.priority,
        });
      })
      .then(() => toggleModal());
  };

  const otherZones = Object.keys(EMPLOYEE_ZONES_ALIASES).filter(zone => zone !== currentZone) as ZoneType[];

  return (
    <Box>
      <DropdownMenu
        sxPaper={{ minWidth: '20px', boxShadow: 'none' }}
        sxList={{ padding: 0 }}
        popupId="change-zones-employee-summary"
        iconButton={
          <Button size="small" sx={{ minWidth: '20px', ...zoneStyles[currentZone as keyof typeof zoneStyles] }}>
            {currentZoneFirstLetter}
            <KeyboardArrowDownIcon sx={{ fontSize: '14px' }} />
          </Button>
        }
      >
        {otherZones.map(zone => (
          <MenuItem
            key={zone}
            sx={{ fontSize: '12px', ...zoneStyles[zone as keyof typeof zoneStyles] }}
            onClick={() => {
              setTargetZone(zone);
              toggleModal();
            }}
          >
            {String(zone).charAt(0)}
          </MenuItem>
        ))}
      </DropdownMenu>

      <IssuesEmployeeModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onSubmit={handleSubmit}
        predefinedIssues={data.retentionRiskIssues}
      />
    </Box>
  );
};

export default ChangeZoneButton;

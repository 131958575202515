import React, { useEffect, useState } from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import CandidateInterviewSteps from '../CandidateInterviewSteps/CandidateInterviewSteps';
import CandidateVacancySpecialization from '../CandidateVacancySpecialization/CandidateVacancySpecialization';
import Stack from '@mui/material/Stack';
import { Stages } from 'erp/recruitment/recruitingBoard/constants';
import EditCandidateInterviewStep from '../EditComponents/EditCandidateInterviewStep/EditCandidateInterviewStep';

const CandidateApplicationSectionWrapper = () => {
  const [error, setError] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<Stages>();
  const { data } = useShowContext();

  useEffect(() => {
    if (!data.lastActiveApplication) {
      setError(true);
    } else if (data.lastActiveApplication) {
      setActiveStep(data.lastActiveApplication.applicationPipelineState.stage);
    }
  }, [data.lastActiveApplication]);

  return (
    <>
      <Stack
        direction="row"
        gap="10px"
        sx={{
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
        }}
      >
        {!error && activeStep && (
          <EditCandidateInterviewStep
            vacancyIdForHiredStep={(data.lastAppliedVacancyData && data.lastAppliedVacancyData.id) ?? ''}
            activeStep={activeStep}
            synchronizeStep={setActiveStep}
          />
        )}
        {data.lastAppliedVacancyData && <CandidateVacancySpecialization vacancyData={data.lastAppliedVacancyData} />}
      </Stack>
      {!error && activeStep && <CandidateInterviewSteps activeStep={activeStep} />}
    </>
  );
};

export default CandidateApplicationSectionWrapper;

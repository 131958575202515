import React, { useState } from 'react';
import Box from 'uibuilder/Box';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import IssuesEmployeeModal from './IssuesEmployeeModal';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { IconButton } from 'uibuilder/button';
import { DashboardEmployee, RetentionRiskIssue } from '../../types';
import useEmployeesDashboardService from '../../useEmployeesDashboardService';
import { RETENTION_RISK_ISSUE_STATUS } from '../../constants';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';

const EditIssueButton = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data, setData } = useShowContext();
  const { retentionRiskId: riskId } = data.getData() as DashboardEmployee;
  const { updateIssues } = useEmployeesDashboardService();
  const { addMessage } = useMessageService();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = async (issues: Partial<RetentionRiskIssue>[]) => {
    updateIssues(riskId, { issues })
      .then(response => {
        setData!({
          ...data.getData(),
          retentionRiskIssues: response.issues?.filter(
            (element: any) => element.status === RETENTION_RISK_ISSUE_STATUS.ACTIVE,
          ),
        });
      })
      .then(() => toggleModal())
      .catch(() => addMessage(new ErrorMessage('Update issue error')));
  };

  return (
    <Box>
      <IconButton sx={{ fontSize: '4px', padding: 0 }} onClick={toggleModal}>
        <AppRegistrationIcon color="primary" />
      </IconButton>

      <IssuesEmployeeModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onSubmit={handleSubmit}
        predefinedIssues={data.retentionRiskIssues}
      />
    </Box>
  );
};

export default EditIssueButton;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from 'uibuilder/Typography';
import EmployeeAvatar from './EmployeeAvatar';
import BirthdayInfo from './BirthdayInfo';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import WorkingStatusChip from './WorkingStatusChip';
import { useEmployeeUrl } from '../../EmployeesRouter';
import { Link } from 'react-router-dom';
import FollowUpSummaryButton from '../shared/buttons/FollowUpSummaryButton/FollowUpSummaryButton';

const EmployeeMainInfo = () => {
  const { data: employee } = useShowContext();
  const { getProfileUrl } = useEmployeeUrl();
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ objectFit: 'cover' }}>
          <EmployeeAvatar avatarId={employee.employeePhotoId} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, gap: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Link
              style={{ fontSize: '16px', wordBreak: 'break-word' }}
              target="_blank"
              to={getProfileUrl(employee.employeeAlias)}
            >
              {employee.employeeName}
            </Link>
            <Box>
              <WorkingStatusChip />
            </Box>
          </Box>
          <Typography sx={{ fontSize: '12px' }}>{employee.employeeSpecialization || '—'}</Typography>
          <BirthdayInfo dateOfBirth={employee.employeeBirthDate} />
        </Box>
      </Box>
      <FollowUpSummaryButton />
    </>
  );
};

export default EmployeeMainInfo;

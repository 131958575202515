// eslint-disable-next-line no-restricted-syntax
import mixpanel from 'mixpanel-browser';

const mixpanelToken = process.env.REACT_APP_DAVINCI_FRONTEND_MIXPANEL_TOKEN;
if (mixpanelToken) {
  mixpanel.init(mixpanelToken, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
    autocapture: true,
    ip: true,
    track_marketing: true,
  });
}

export default {
  identify: id => {
    if (mixpanelToken) mixpanel.identify(id);
  },
  alias: id => {
    if (mixpanelToken) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (mixpanelToken) mixpanel.track(name, props);
  },
  people: {
    set: props => {
      if (mixpanelToken) mixpanel.people.set(props);
    },
  },
};

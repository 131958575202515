/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import CreateButton from 'uibuilder/button/CreateButton';
import { BooleanField, FullDateField, SearchLinkField, TextField } from 'shared/uibuilder/field';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import useServiceService, { CREATE_SERVICE, UPDATE_SERVICE } from 'erp/service/shared/serviceService';
import { useServiceUrl } from 'erp/service/ServiceRouter';
import EditServiceButton from 'erp/service/List/button/EditServiceButton';
import { EmployeeLinkField } from 'erp/employee';
import { CREATE_WASTE } from 'erp/costaccounting/waste/useWasteService';
import useWasteUrl from 'erp/costaccounting/waste/useWasteUrl';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';

const ServiceList = () => {
  const { search } = useServiceService();
  const { getCreateUrl, getSingleEntityUrl } = useServiceUrl();
  const { getCreateUrl: getWasteCreateUrl } = useWasteUrl();

  return (
    <List getDataMethod={search} defaultSortOrder="DESC" defaultSortField="updatedAt">
      <ListPageLayout
        data-hj-suppress
        header={<ListHeader label="Services" icon="fa-folder-o" />}
        actions={[
          <CreateButton
            url={getWasteCreateUrl()}
            label="Report Waste"
            key="create-waste"
            permissionToCheck={CREATE_WASTE}
          />,
          <CreateButton
            url={getCreateUrl()}
            label="Create Service"
            key="create-service"
            permissionToCheck={CREATE_SERVICE}
          />,
        ]}
        search={<SearchInput label="Service Name" />}
      >
        <DataGridLayout
          buttons={[<EditServiceButton key="edit-service" source="id" outline permissionToCheck={UPDATE_SERVICE} />]}
        >
          <SearchLinkField source="id" label="Service ID" url={getSingleEntityUrl} isSortable width={140} />
          <TextField source="name" label="Service Name" isSortable />
          <BudgetLinkField
            label="Default Budget"
            source="defaultBudgetId"
            nameSource="defaultBudget.name"
            width={140}
          />
          <EmployeeLinkField
            source="ownerId"
            sortSource="ownerNameEn"
            nameSource="ownerName"
            label="Owner"
            width={150}
            isSortable
          />
          <FullDateField source="updatedAt" label="Last Modified Date" isSortable />
          <BooleanField source="assignmentForbidden" label="Assignment Forbidden" isSortable />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default ServiceList;

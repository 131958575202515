import React, { useEffect, useState } from 'react';
import { MoveToStages, Stages, StagesOptions } from 'erp/recruitment/recruitingBoard/constants';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';
import useCandidateService from 'erp/candidate/candidateService';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import RejectStageForm from 'erp/recruitment/recruitingBoard/shared/forms/RejectForm/RejectForm';
import DecisionForm from 'erp/recruitment/recruitingBoard/shared/forms/DecisionForm/DecisionForm';
import HiredForm from 'erp/recruitment/recruitingBoard/shared/forms/HiredForm/HiredForm';
import Box from '@mui/material/Box';
import { ModalsWithAdditionalRequirements } from 'erp/recruitment/newVacancy/types';
import MoveToInterviewForm from 'erp/recruitment/recruitingBoard/shared/forms/MoveToInterviewForm/MoveToInterviewForm';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export interface EditCandidateInterviewStepProps {
  synchronizeStep: any;
  activeStep: Stages;
  vacancyIdForHiredStep: string;
}

const EditCandidateInterviewStep: React.FC<EditCandidateInterviewStepProps> = ({
  activeStep,
  synchronizeStep,
  vacancyIdForHiredStep,
}) => {
  const modalMap: Partial<ModalsWithAdditionalRequirements> = {
    [Stages.Interview]: MoveToInterviewForm,
    [Stages.Hired]: HiredForm,
    [Stages.Decision]: DecisionForm,
    [Stages.PendingRejection]: RejectStageForm,
  };

  const { data } = useShowContext();
  const { changeCandidateApplicationPiplineStep } = useCandidateService();

  const [ActiveModal, setActiveModal] = useState<React.ComponentType<any> | null>(null);
  const [dataForUpdateStep, setDataForUpdateStep] = useState<any>(null);
  const [entityObj, setEntityObj] = useState<any>(null);
  const [errorStepUpdating, setErrorStepUpdating] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (data.lastActiveApplication) {
        setEntityObj(data.lastActiveApplication);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStepHandler = async (values: FormFieldsData) => {
    setDataForUpdateStep(values);

    const ActiveModalComponent = modalMap[values.EditCandidateInterviewStep as Stages];

    if (ActiveModalComponent) {
      setActiveModal(() => ActiveModalComponent);
    } else {
      changeStepHandlerStep2(values);
    }
  };

  const changeStepWithRequirementsHandler = async (values: FormFieldsData) => {
    changeStepHandlerStep2(dataForUpdateStep, values);
  };

  const changeStepHandlerStep2 = async (values: FormFieldsData, additionalValues?: FormFieldsData) => {
    if (typeof values?.EditCandidateInterviewStep !== 'undefined') {
      const stageKey = values.EditCandidateInterviewStep as keyof typeof MoveToStages;
      const updatedData = await changeCandidateApplicationPiplineStep(
        data.lastActiveApplication.id,
        MoveToStages[stageKey] as Stages,
        additionalValues,
      );
      if (updatedData !== false) {
        synchronizeStep(stageKey);
        setErrorStepUpdating(false);
      } else {
        setErrorStepUpdating(true);
      }
    }
  };

  const closeDialog = () => {
    setActiveModal(null);
  };

  return (
    <Box
      sx={{
        '&>.MuiFormControl-root': {
          marginBottom: 0,
          width: '150px',
          '& .dropdown__control': {
            backgroundColor: '#5954A8',
            color: '#ffffff',
            padding: '0px 4px',
            minHeight: 'initial',
            '& .dropdown__value-container': {
              minHeight: 'initial',
            },
          },
          '& .dropdown__placeholder': {
            color: errorStepUpdating ? '#666666' : '#ffffff',
          },
          '& .dropdown__indicator svg': {
            fill: '#ffffff !important',
          },
          '& .dropdown__value': {
            minHeight: 'initial',
          },
          '& .dropdown__input-container': {
            padding: 0,
            margin: 0,
            '& .dropdown__input': {
              color: '#ffffff !important',
              caretColor: '#5954A8',
            },
          },
          '& .dropdown__menu': {
            marginTop: 0,
            '& .dropdown__menu-list': {
              backgroundColor: '#5954A8',
              borderRadius: '3px',
              '& .dropdown__option': {
                color: '#ffffff',
                '&:hover': {
                  background: 'rgba(0, 0, 0, 0.24)',
                },
              },
            },
          },
        },
      }}
    >
      <EnumDropdown
        placeholder="Move to"
        options={StagesOptions}
        source="EditCandidateInterviewStep"
        label=""
        onChangeCallback={changeStepHandler}
        disabled={errorStepUpdating}
        validationCallback={() => {}}
        clearValidationCallback={() => {}}
      />
      {ActiveModal && (
        <ModalWindow
          isOpen
          key="modal-not-strict"
          modalSize="sm"
          backdrop="static"
          title="Additional info required"
          onToggle={closeDialog}
        >
          <CreateForm
            submitFormFunc={formData => changeStepWithRequirementsHandler(formData)}
            afterSubmit={{
              execute: () => {
                setActiveModal(null);
              },
            }}
          >
            <ActiveModal
              handleCancelClick={closeDialog}
              sourceStage={activeStep}
              vacancyGroupId={vacancyIdForHiredStep}
              entity={entityObj}
            />
          </CreateForm>
        </ModalWindow>
      )}
    </Box>
  );
};

export default EditCandidateInterviewStep;

/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import CandidateOfferListInput from '../CandidateOfferListInput';
import CandidateOfferCompensationDetailsInput from '../CandidateOfferCompensationDetailsInput';
import { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';
import Box from '@mui/material/Box';
import FormButtonsLayout from 'shared/layout/form/FormButtonsLayout';
import useCandidateOfferService from 'erp/candidate/Timeline/offer/candidateOfferService';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

interface CreateOfferFormProps extends FormTemplateProps {
  onCancel?: () => void;
}

const UpdateCandidateOfferForm = ({ onCancel, messageContext }: CreateOfferFormProps) => {
  const { update, getById, getValidationSchema } = useCandidateOfferService();
  const { reloadData } = useListContext();
  const { data: entity } = useShowContext();

  return (
    <UpdateForm
      messageContext={messageContext}
      getDataFunc={() => getById(entity.offerId)}
      submitFormFunc={update}
      afterSubmit={{
        execute: () => {
          if (reloadData) {
            reloadData();
          }
          if (onCancel) {
            onCancel();
          }
        },
        message: 'You are awesome! The Offer has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      entityId={entity.offerId}
    >
      <CandidateOfferListInput
        isVisible
        source="compensationDetails"
        maxFields={4}
        addText="Update Compensation Details"
        inputTemplate={(props: any) => <CandidateOfferCompensationDetailsInput {...props} />}
      />

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <FormButtonsLayout onCancelAction={onCancel} />
      </Box>
    </UpdateForm>
  );
};

export default UpdateCandidateOfferForm;

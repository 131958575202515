import invoiceValidation from 'financialAnalytic/invoice/createupdate/invoiceValidation';
import invoiceUpdateValidation from 'financialAnalytic/invoice/createupdate/Update/invoiceUpdateValidation';
import { useFinanceApi } from 'api';
import useCrudService from 'shared/crud';
import { CONTENT_TYPE } from 'shared/api/const';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import { downloadFile } from 'shared/fileHelper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export const READ_INVOICE_LIST = 'COMPANY_READ_INVOICE';
export const READ_INVOICE = 'COMPANY_READ_INVOICE';
export const UPDATE_INVOICE = 'COMPANY_UPDATE_INVOICE';
export const CREATE_INVOICE = 'COMPANY_CREATE_INVOICE';
export const REJECT_INVOICE = 'COMPANY_REJECT_INVOICE';
export const MARK_AS_PAID_INVOICE = 'COMPANY_MARK_AS_PAID_INVOICE';
export const MARK_AS_PAID_INVOICE_PARTIALLY = 'COMPANY_MARK_AS_PAID_INVOICE_PARTIALLY';
export const RUN_INVOICE_SUBTOTAL_UPDATE = 'COMPANY_CREATE_PERFORMANCE_BASED_BONUS';
export const APPROVE_INVOICE = 'COMPANY_APPROVE_INVOICE';
export const INVOICE_PATH = '/invoices';
export const RESOURCE_URL = '/procurement/invoices';
export const PERFORM_MIGRATION = 'COMPANY_MIGRATE_QLDB_ENTITIES';

export const INVOICE_STATUSES = {
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
};

export const INVOICE_STATUSES_OPTIONS = {
  [INVOICE_STATUSES.REJECTED]: 'Rejected',
  [INVOICE_STATUSES.APPROVED]: 'Approved',
  [INVOICE_STATUSES.PENDING]: 'Pending',
  [INVOICE_STATUSES.PAID]: 'Paid',
  [INVOICE_STATUSES.PARTIALLY_PAID]: 'Partially paid',
};

export const INVOICE_CLASSIFICATION = {
  ASSET: 'ASSET',
  OTHER: 'OTHER',
};
export const INVOICE_CLASSIFICATION_OPTIONS = {
  [INVOICE_CLASSIFICATION.ASSET]: 'Classified as asset',
  [INVOICE_CLASSIFICATION.OTHER]: 'Other',
};

export const INVOICE_PAGE_SIZE = 50;

const useInvoiceService = () => {
  const { sendPostRequest, sendPutRequest } = useFinanceApi();

  const {
    search: baseSearch,
    create: initialCreate,
    update: initialUpdate,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useFinanceApi,
  });

  const search = (request: ResourceData) => {
    const params = cloneDeep(request);

    ['isInvoiceDocumentWillArriveLater', 'isFutureExpense'].forEach(source => {
      const filterValue = params.filter?.[source]?.eq || '';

      if (filterValue && params.filter) {
        params.filter[source] = {
          eq: filterValue === 'YES',
        };
      }
    });

    return baseSearch(params);
  };

  const getSensitiveDataByIds = async (ids: string[]) => {
    if (!ids.length) {
      return {
        totalElements: 0,
        totalPages: 1,
        result: [],
      };
    }

    const result = await sendPostRequest(`${RESOURCE_URL}/subtotals`, ids);

    return result.json();
  };

  const create = async (data: any) =>
    initialCreate({
      ...data,
      invoiceDocumentId: data.invoiceDocumentId ? data.invoiceDocumentId[0] : null,
      subTotal: {
        ...data.subTotal,
        currency: data.subTotal.currency === 'BYN' ? 'BYR' : data.subTotal.currency,
      },
      isPurchaseClassifiedAsAsset: data.isPurchaseClassifiedAsAsset === INVOICE_CLASSIFICATION.ASSET,
    });

  const update = async (id: StringOrNumber, { invoiceNumber, isFinalChanges, invoiceDocumentId }: FormFieldsData) =>
    initialUpdate(id, {
      invoiceDocumentId: invoiceDocumentId[0],
      invoiceNumber,
      isFinalChanges,
    });

  const rejectInvoice = async (id: string, rejectReason: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/reject`, rejectReason, CONTENT_TYPE.MULTIPART);
    return result.json();
  };

  const cancelInvoicePayment = async (id: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/cancel-payment`);
    return result.json();
  };

  const markAsPaidInvoice = async (
    id: string,
    {
      invoicePaidDate,
      bankTransactionId,
      isPartialPayment,
      partialPaymentSum,
    }: { partialPaymentSum?: number; isPartialPayment: boolean; invoicePaidDate: string; bankTransactionId?: string },
  ) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/mark-as-paid`, {
      invoicePaidDate,
      bankTransactionId,
      isPartialPayment,
      partialPaymentSum,
    });

    return result.json();
  };

  const approveInvoice = async (id: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/approve`);
    return result.json();
  };

  const markAsDeliveredGoodsAndServices = async (
    linkedInvoiceId: string,
    {
      goodsAndServicesDeliveryDate,
      isPartialDelivery,
      partialDeliverySum,
    }: {
      goodsAndServicesDeliveryDate: string;
      isPartialDelivery: boolean;
      partialDeliverySum?: number;
    },
  ) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${linkedInvoiceId}/mark-as-delivered`, {
      goodsAndServicesDeliveryDate,
      isPartialDelivery,
      partialDeliverySum,
    });

    return result.json();
  };

  const runUpdateInvoiceSubTotals = async (data: Dictionary<string>) => {
    return sendPutRequest(`${RESOURCE_URL}/sub-totals`, data);
  };

  const runDownloadInvoicesReport = async (filter: any, sortParams: any) => {
    const sort =
      sortParams === undefined || Object.keys(sortParams).length === 0
        ? {}
        : {
            [sortParams.field]: sortParams.order,
          };

    const searchRequest = {
      filter,
      pageSize: INVOICE_PAGE_SIZE,
      sort,
    };

    const response = await sendPostRequest('/procurement/invoices/report/csv', searchRequest);

    return downloadFile(response, decodeURI('invoices-report.csv'), true);
  };

  const runInvoiceMigration = async (data: Dictionary<string>) => {
    return sendPostRequest(`${RESOURCE_URL}/migrate-subtotals`, data);
  };

  const runInvoiceValidation = async (data: Dictionary<string>) => {
    return sendPostRequest(`${RESOURCE_URL}/validate-subtotals`, data);
  };

  const getValidationSchema = () => Promise.resolve(invoiceValidation);

  const getUpdateValidationSchema = () => Promise.resolve(invoiceUpdateValidation);

  return {
    ...baseCrud,
    create,
    update,
    getValidationSchema,
    rejectInvoice,
    getSensitiveDataByIds,
    markAsPaidInvoice,
    approveInvoice,
    getUpdateValidationSchema,
    cancelInvoicePayment,
    search,
    markAsDeliveredGoodsAndServices,
    runUpdateInvoiceSubTotals,
    runDownloadInvoicesReport,
    runInvoiceMigration,
    runInvoiceValidation,
  };
};

export default useInvoiceService;

import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from '@mui/material';
import AvatarSection from '../AvatarSection/AvatarSection';
import SkillsBadges from '../Badges/SkillsBadges/SkillsBadges';
import CandidateApplicationSectionWrapper from '../CandidateApplicationSectionWrapper/CandidateApplicationSectionWrapper';
import CandidateStepsStatuses from '../CandidateStepsStatuses/CandidateStepsStatuses';
import { CandidateEmailField, CandidatePhoneField, CandidateDuplicateOfField } from '../ContactField/ContactField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ApplicationsHistory from '../ApplicationsHistory';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';

interface Specialization {
  specialization: string;
  qualificationLevel: string;
  isConfirmed: boolean;
}

const CandidateProfileShowFields = () => {
  const { data } = useShowContext();

  const confirmedSpecializations = useMemo(() => {
    if (!data?.specializations?.length) return [];

    const allConfirmed = data.specializations.filter((spec: Specialization) => spec.isConfirmed === true);

    const uniqueSpecializations: Record<string, Specialization> = {};

    allConfirmed.forEach((spec: Specialization) => {
      if (!uniqueSpecializations[spec.specialization]) {
        uniqueSpecializations[spec.specialization] = spec;
      }
    });

    return Object.values(uniqueSpecializations);
  }, [data?.specializations]);

  const sectionFields = [
    <AvatarSection />,
    <Stack direction="column" gap="0.5rem">
      <CandidateApplicationSectionWrapper />
      <CandidateStepsStatuses />
    </Stack>,
    <Stack direction="column" gap="0.5rem">
      {confirmedSpecializations.length > 0 && (
        <Box display="flex" alignItems="flex-start" gap={1}>
          <Typography variant="body1">Confirmed Level{confirmedSpecializations.length > 1 ? 's' : ''}:</Typography>
          <Typography variant="body1">
            {confirmedSpecializations.map((spec, index) => (
              <React.Fragment key={spec.specialization}>
                <strong>{spec.qualificationLevel}</strong> <span>{spec.specialization}</span>
                {index < confirmedSpecializations.length - 1 && ', '}
              </React.Fragment>
            ))}
          </Typography>
        </Box>
      )}
      <CandidateEmailField source="personalEmail" />
      <CandidateEmailField source="otherEmail" />
      <CandidatePhoneField source="personalPhoneNumber" />
      <CandidatePhoneField source="otherPhoneNumber" />
      {data.duplicateOf && data.duplicateOf.id ? (
        <CandidateDuplicateOfField
          duplicateOfId={data.duplicateOf.id}
          duplicateFullName={data.duplicateOf.name.fullName}
        />
      ) : null}
    </Stack>,
  ];

  return (
    <>
      {sectionFields.map((section, index) => {
        const sectionId = uuidv4();
        return (
          <Stack
            key={sectionId}
            sx={{
              borderBottom: '1px solid #D9D9D9',
              padding: `${index === 0 ? '0' : '0.5rem'} 0 0.5rem 0 !important`,
              margin: '0 !important',
            }}
          >
            {section}
          </Stack>
        );
      })}
      <SkillsBadges />
      {data.previousApplications && data.previousApplications.length > 0 && (
        <ApplicationsHistory applications={data.previousApplications} />
      )}
    </>
  );
};

export default CandidateProfileShowFields;

import React from 'react';
import ControlledShow from 'shared/uibuilder/show/ControlledShow';
import Box from 'uibuilder/Box';
import EmployeeDashboardItem from '../EmployeeDashboardItem/EmployeeDashboardItem';
import { DashboardEmployee, EmployeeZoneAlias } from '../../types';

const EmployeeDashboardList = ({
  zone,
  items: employeesData,
  updateEntityInList,
}: {
  zone: EmployeeZoneAlias;
  items: DashboardEmployee[];
  updateEntityInList: any;
}) => {
  return (
    <Box sx={{ minWidth: '1000px' }}>
      {employeesData.map((employee: DashboardEmployee) => (
        <ControlledShow
          data={{
            ...employee,
            currentZone: zone,
          }}
          setData={updateEntityInList}
        >
          <EmployeeDashboardItem />
        </ControlledShow>
      ))}
    </Box>
  );
};

export default EmployeeDashboardList;

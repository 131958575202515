/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import useAuthorization from 'shared/authorization/authorizationService';
import { UPDATE_CANDIDATE_OFFER_ATTACHMENTS } from 'erp/candidate/candidateService';
import CandidateOfferListInput from '../CandidateOfferListInput';
import CandidateOfferCompensationDetailsInput from '../CandidateOfferCompensationDetailsInput';
import { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';
import Box from '@mui/material/Box';
import FormButtonsLayout from 'shared/layout/form/FormButtonsLayout';
import useCandidateOfferService from 'erp/candidate/Timeline/offer/candidateOfferService';
import { useListContext } from 'shared/uibuilder/list/ListContext';

interface CreateOfferFormProps extends FormTemplateProps {
  onCancel?: () => void;
}

const CreateCandidateOfferForm = ({ onCancel, messageContext }: CreateOfferFormProps) => {
  const { isGranted } = useAuthorization();
  const { createOffer, getValidationSchema } = useCandidateOfferService();
  const isOnlyAttachments = isGranted(UPDATE_CANDIDATE_OFFER_ATTACHMENTS);

  const { reloadData } = useListContext();

  return (
    <CreateForm
      messageContext={messageContext}
      initialData={{
        compensationDetails: [],
      }}
      submitFormFunc={createOffer}
      afterSubmit={{
        execute: () => {
          if (reloadData) {
            reloadData();
          }
          if (onCancel) {
            onCancel();
          }
        },
        message: 'You are awesome! The offer has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CandidateOfferListInput
        isVisible={!isOnlyAttachments}
        source="compensationDetails"
        maxFields={4}
        addText="Add Compensation Details"
        inputTemplate={(props: any) => <CandidateOfferCompensationDetailsInput {...props} />}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <FormButtonsLayout onCancelAction={onCancel} />
      </Box>
    </CreateForm>
  );
};

export default CreateCandidateOfferForm;

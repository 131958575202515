import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Box, Typography, Chip, Paper } from '@mui/material';
import moment from 'moment';
import { LinkField } from 'shared/uibuilder/field';
import { useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

interface ApplicationState {
  stage: string;
  rejectedFrom: string | null;
  jobsStates: any[];
  stageTransitionTimestamp: string;
  info?: any[];
  undoStates: string[];
}

interface Application {
  id: number;
  version: number;
  candidateId: string;
  candidateName: string;
  vacancyGroupAlias: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  applicationPipelineState: ApplicationState;
  source: string;
  sourceType: string;
  sourceComment: string | null;
  historySource: string | null;
  sourceEmployeeId: string | null;
  wasHired: boolean;
  isTestSubmission: boolean;
}

interface ApplicationsHistoryProps {
  applications: Application[];
}

const getStatusColor = (stage: string) => {
  switch (stage.toLowerCase()) {
    case 'rejected':
      return '#f44336';
    case 'hired':
      return '#4caf50';
    case 'screening':
      return '#2196f3';
    case 'applied':
      return '#ff9800';
    default:
      return '#9e9e9e';
  }
};

const ApplicationsHistory: React.FC<ApplicationsHistoryProps> = ({ applications }) => {
  const { getSingleEntityUrl } = useVacancyGroupUrl();
  const { data } = useShowContext();

  return (
    <Box sx={{ mt: 3, mb: 4 }}>
      <Typography variant="h6" gutterBottom sx={{ fontSize: '1.15rem' }}>
        Application History
      </Typography>
      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
          bulletActiveClass: 'swiper-pagination-bullet-active',
        }}
        spaceBetween={20}
        slidesPerView={1}
        style={{ padding: '0 0 40px 0' }}
        autoHeight={false}
      >
        {applications.map(application => {
          const vacancyGroup = data.vacancyGroups[application.vacancyGroupAlias];
          const vacancyGroupURL = vacancyGroup?.id ? getSingleEntityUrl(vacancyGroup.id) : '';

          return (
            <SwiperSlide key={application.id}>
              <Paper
                elevation={2}
                sx={{
                  p: 2,
                  margin: 1,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '137px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <LinkField
                    linkText={vacancyGroup?.specialization || application.vacancyGroupAlias}
                    value={vacancyGroupURL}
                  />
                  <Chip
                    label={application.applicationPipelineState.stage}
                    sx={{
                      bgcolor: getStatusColor(application.applicationPipelineState.stage),
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flexGrow: 1 }}>
                  {application.applicationPipelineState.rejectedFrom && (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography sx={{ fontWeight: 'medium' }}>Rejected From:</Typography>
                      <Typography>{application.applicationPipelineState.rejectedFrom}</Typography>
                    </Box>
                  )}
                  {application.applicationPipelineState.stage === 'Rejected' && (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography sx={{ fontWeight: 'medium', flex: '0 0 auto' }}>Rejected Reason:</Typography>
                      <Typography>
                        {
                          application.applicationPipelineState.info?.find(info => info.type === 'Rejected')
                            ?.rejectedReason
                        }
                      </Typography>
                    </Box>
                  )}

                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography sx={{ fontWeight: 'medium' }}>Last Update:</Typography>
                    <Typography>{moment(application.updatedAt).format('MMM DD, YYYY')}</Typography>
                  </Box>
                </Box>
              </Paper>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default ApplicationsHistory;

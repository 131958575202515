import EMPLOYEE_VALIDATION, { CAREER_REVIEW_VALIDATION } from 'erp/employee/createupdate/employeeValidation';
import { useKernelApi } from 'api';
import useTimelineService from 'shared/crud/timelineService';
import useEmployeeMapper from 'erp/employee/employeeMapper';
import useCrudService from 'shared/crud';
import useAuthenticatedUserService, { UserName } from 'authentication/authenticatedUserService';
import useEmployeeSuggestionsService from 'erp/employee/employeeSuggestionsService';
import useFeatureToggle, { Features } from 'featuretoggle';
import { useSearchParameters } from '../../shared/routing/helper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export const MY_PAGE_PARAMETER = 'me';

export const DISMISSED_STATUS = 'DISMISSED';
export const ACTIVE_STATUS = 'ACTIVE';
export const IN_DISMISSAL_STATUS = 'IN_DISMISSAL';
export const RELOCATION_STATUS = 'RELOCATION';

export const ASSIGN_MENTOR = 'ASSIGN_MENTOR';

export const EMPLOYEE_READ_NAME = 'EMPLOYEE_READ_NAME';

export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const GET_EMPLOYEES_LIST = 'GET_EMPLOYEES_LIST';
export const READ_EMPLOYEE_PERMISSION = 'READ';
export const UPDATE_EMPLOYEE = 'UPDATE';
export const DELETE_EMPLOYEE = 'DELETE';
export const READ_EDUCATIONS_LIST = 'READ_EDUCATIONS_LIST';
export const READ_EMPLOYEE_COMPENSATION_PACKAGES = 'READ_EMPLOYEE_COMPENSATION_PACKAGES';
export const READ_EMPLOYEE_KNOWLEDGE_PACKS = 'READ_EMPLOYEE_KNOWLEDGE_PACKS';
export const READ_EMPLOYEE_COMMUNICATIONS = 'READ_EMPLOYEE_COMMUNICATIONS';
export const READ_CONTRACTS_LIST = 'READ_CONTRACTS_LIST';
export const READ_EMPLOYEE_CONTRACTS = 'READ_EMPLOYEE_CONTRACTS';
export const READ_EMPLOYEE_MAIN_PERSONAL_INFO = 'READ_EMPLOYEE_MAIN_PERSONAL_INFO';
export const READ_EMPLOYEE_MAIN_HR_INFORMATION = 'READ_EMPLOYEE_MAIN_HR_INFORMATION';
export const READ_EMPLOYEE_CONTACT_INFORMATION = 'READ_EMPLOYEE_CONTACT_INFORMATION';
export const READ_EMPLOYEE_QUALIFICATIONS = 'READ_EMPLOYEE_QUALIFICATIONS';
export const UPDATE_EMPLOYEE_QUALIFICATIONS = 'FE_UPDATE_EMPLOYEE_QUALIFICATION';
export const DELETE_EMPLOYEE_SPECIALIZATION = 'FE_DELETE_EMPLOYEE_SPECIALIZATION';
export const READ_EMPLOYEE_SPECIALIZATION = 'FE_READ_EMPLOYEE_SPECIALIZATION';
export const SET_PRIMARY_EMPLOYEE_SPECIALIZATION = 'SET_PRIMARY_EMPLOYEE_SPECIALIZATION';
export const CREATE_EMPLOYEE_QUALIFICATIONS = 'CREATE_QUALIFICATION';
export const UPDATE_EMPLOYEE_COMPETENCY = 'UPDATE_EMPLOYEE_COMPETENCY';
export const READ_EMPLOYEE_WORKING_STATUS = 'READ_EMPLOYEE_WORKING_STATUS';
export const READ_EMPLOYMENT_AGE_INFORMATION = 'READ_EMPLOYMENT_AGE_INFORMATION';
export const READ_EMPLOYEE_IDENTITY_DOCUMENTS = 'READ_EMPLOYEE_IDENTITY_DOCUMENTS';
export const READ_EMPLOYEE_FAMILY_INFORMATION = 'READ_EMPLOYEE_FAMILY_INFORMATION';
export const READ_EMPLOYEE_MILITARY_INFO = 'READ_EMPLOYEE_MILITARY_INFO';
export const READ_EMPLOYEE_ACCOUNTS_AZURE_ID = 'READ_EMPLOYEE_ACCOUNTS_AZURE_ID';
export const READ_EMPLOYEE_ACCOUNTS_1C_ID = 'READ_EMPLOYEE_ACCOUNTS_1C_ID';
export const READ_EMPLOYEE_TIMELINE = 'READ_EMPLOYEE_TIMELINE';
export const READ_EMPLOYEE_INITIALIZATION_STATE = 'READ_INITIALIZATION_STATE';
export const READ_EMPLOYEE_SPECIALIZATION_INFO = 'READ_EMPLOYEE_SPECIALIZATION_INFO';
export const READ_EMPLOYEE_REQUISITES_INFORMATION = 'READ_EMPLOYEE_REQUISITES_INFORMATION';
export const UPDATE_EMPLOYEE_INITIALIZATION_STATE = 'UPDATE_INITIALIZATION_STATE';
export const UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO = 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO';
export const UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS = 'UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS';
export const UPDATE_EMPLOYEE_CONTACT_INFORMATION = 'UPDATE_EMPLOYEE_CONTACT_INFORMATION';
export const UPDATE_EMPLOYEE_FAMILY_INFORMATION = 'UPDATE_EMPLOYEE_FAMILY_INFORMATION';
export const UPDATE_EMPLOYEE_MILITARY_INFO = 'UPDATE_EMPLOYEE_MILITARY_INFO';
export const UPDATE_EMPLOYEE_MAIN_HR_INFORMATION = 'UPDATE_EMPLOYEE_MAIN_HR_INFORMATION';
export const UPDATE_EMPLOYEE_ACCOUNTS_AZURE_ID = 'UPDATE_EMPLOYEE_ACCOUNTS_AZURE_ID';
export const UPDATE_EMPLOYEE_ACCOUNTS_1C_ID = 'UPDATE_EMPLOYEE_ACCOUNTS_1C_ID';
export const UPDATE_EMPLOYEE_WORKING_STATUS = 'UPDATE_EMPLOYEE_WORKING_STATUS';
export const UPDATE_EMPLOYEE_REQUISITES_INFORMATION = 'UPDATE_EMPLOYEE_REQUISITES_INFORMATION';
export const UPDATE_EMPLOYEE_EXCHANGE_IDENTIFIER = 'UPDATE_EMPLOYEE_EXCHANGE_IDENTIFIER';
export const UPDATE_EMPLOYEE_SPECIALIZATION_INFO = 'UPDATE_EMPLOYEE_SPECIALIZATION_INFO';
export const UPDATE_EMPLOYEE_TIMEZONE = 'UPDATE_EMPLOYEE_TIMEZONE';
export const READ_CAREER_DEVELOPMENT_NEXT_REVIEW_DATE = 'READ_CAREER_DEVELOPMENT_NEXT_REVIEW_DATE';
export const READ_EMPLOYEE_CAREER_DEVELOPMENT_INFORMATION = 'READ_EMPLOYEE_CAREER_DEVELOPMENT_INFORMATION';
export const UPDATE_EMPLOYEE_CAREER_DEVELOPMENT_INFORMATION = 'UPDATE_EMPLOYEE_CAREER_DEVELOPMENT_INFORMATION';
export const UPDATE_CAREER_DEVELOPMENT_NEXT_REVIEW_DATE = 'UPDATE_CAREER_DEVELOPMENT_NEXT_REVIEW_DATE';
export const CREATE_EMPLOYEE_SALARY = 'CREATE_EMPLOYEE_SALARY';
export const READ_LIST_EMPLOYEE_SALARY = 'READ_LIST_EMPLOYEE_SALARY';
export const CREATE_EMPLOYEE_OFFER = 'CREATE_EMPLOYEE_OFFER';
export const READ_LIST_EMPLOYEE_OFFER = 'READ_LIST_EMPLOYEE_OFFER';
export const CREATE_EMPLOYEE_PERFORMANCE = 'CREATE_EMPLOYEE_PERFORMANCE';
export const READ_LIST_EMPLOYEE_PERFORMANCE = 'READ_LIST_EMPLOYEE_PERFORMANCE';

export const EDIT_EMPLOYEE_PAGE_PERMISSIONS = [
  UPDATE_EMPLOYEE_ACCOUNTS_1C_ID,
  UPDATE_EMPLOYEE_ACCOUNTS_AZURE_ID,
  UPDATE_EMPLOYEE_CONTACT_INFORMATION,
  UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO,
  UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS,
  UPDATE_EMPLOYEE_FAMILY_INFORMATION,
  UPDATE_EMPLOYEE_MILITARY_INFO,
  UPDATE_EMPLOYEE_MAIN_HR_INFORMATION,
  UPDATE_EMPLOYEE_WORKING_STATUS,
  UPDATE_EMPLOYEE_REQUISITES_INFORMATION,
];

export const FINALIZE_KEY = 'state';
export const FINALIZE_STATE = 'FINALIZE';

export const EMPLOYEE_STATE_KEYS = {
  NEW: 'NEW',
  FINALIZED: 'FINALIZED',
};

export const EMPLOYEE_WORKING_STATUSES: any = {
  ACTIVE: 'Active',
  IN_DISMISSAL: 'In Dismissal',
  DISMISSED: 'Dismissed',
  RELOCATION: 'Relocation',
};

const EMPLOYEE_WORKPLACE_LOCATION_IDS = {
  officeOnly: 'OFFICE_ONLY',
  remoteOnly: 'REMOTE_ONLY',
  hybrid: 'HYBRID',
};

export const EMPLOYEE_WORKPLACE_LOCATION = {
  [EMPLOYEE_WORKPLACE_LOCATION_IDS.officeOnly]: 'Office only',
  [EMPLOYEE_WORKPLACE_LOCATION_IDS.remoteOnly]: 'Remote only',
  [EMPLOYEE_WORKPLACE_LOCATION_IDS.hybrid]: 'Hybrid',
};

export const DOCUMENTS_ALIAS = {
  PASSPORT: 'PASSPORT',
  RESIDENCE_PERMIT: 'RESIDENCE_PERMIT',
  PESEL: 'PESEL',
  IDENTITY_CARD: 'IDENTITY_CARD',
};

export const DOCUMENTS = {
  PASSPORT: 'Passport',
  RESIDENCE_PERMIT: 'Residence Permit',
  PESEL: 'PESEL',
  IDENTITY_CARD: 'Identity card',
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const isInternEmployeeTypeEnabled = useFeatureToggle().isFeatureEnabled(Features.VIEW_INTERN_TYPE);

export const EMPLOYEE_TYPES: any = isInternEmployeeTypeEnabled
  ? {
      EMPLOYEE: 'Employee',
      CONTRACTOR: 'Contractor',
      INTERN: 'Intern',
    }
  : {
      EMPLOYEE: 'Employee',
      CONTRACTOR: 'Contractor',
    };

export const EMPLOYEE_TYPES_ALIAS = Object.fromEntries(Object.keys(EMPLOYEE_TYPES).map(key => [key, key]));

export const updateEmployeePermissions = {
  nameLocal: [UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO],
  nameEn: [UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO],
  workingEmail: [UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO],
  gender: [UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO],
  azureId: [UPDATE_EMPLOYEE_ACCOUNTS_AZURE_ID],
  officeId: [UPDATE_EMPLOYEE_MAIN_HR_INFORMATION],
  resourcePoolId: [UPDATE_EMPLOYEE_MAIN_HR_INFORMATION],
  positionId: [UPDATE_EMPLOYEE_MAIN_HR_INFORMATION],
  workingStatus: [UPDATE_EMPLOYEE_WORKING_STATUS],
  employeeType: [UPDATE_EMPLOYEE_MAIN_HR_INFORMATION],
  exchangeId: [UPDATE_EMPLOYEE_EXCHANGE_IDENTIFIER],
  timezone: [UPDATE_EMPLOYEE_TIMEZONE],
  specializationInfo: [UPDATE_EMPLOYEE_SPECIALIZATION_INFO],
  assignedPeopleManagerAliases: [UPDATE_EMPLOYEE_MAIN_HR_INFORMATION],
  careerDevelopmentEvaluationMode: [UPDATE_EMPLOYEE_CAREER_DEVELOPMENT_INFORMATION],
  extendedInformation: {
    photosIds: [UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO],
    dateOfBirth: [UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO],
    homeAddress: [UPDATE_EMPLOYEE_CONTACT_INFORMATION],
    registrationAddress: [UPDATE_EMPLOYEE_CONTACT_INFORMATION],
    personalEmail: [UPDATE_EMPLOYEE_CONTACT_INFORMATION],
    personalPhoneNumber: [UPDATE_EMPLOYEE_CONTACT_INFORMATION],
    otherPhoneNumber: [UPDATE_EMPLOYEE_CONTACT_INFORMATION],
    additionalContactInfo: [UPDATE_EMPLOYEE_CONTACT_INFORMATION],
    plannedMidTermLocation: [UPDATE_EMPLOYEE_CONTACT_INFORMATION],
    plannedPermLocation: [UPDATE_EMPLOYEE_CONTACT_INFORMATION],
    locationUpdateDate: [UPDATE_EMPLOYEE_CONTACT_INFORMATION],
    financialInformation: [UPDATE_EMPLOYEE_ACCOUNTS_1C_ID],
    identityDocuments: [UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS],
    hrInformation: [UPDATE_EMPLOYEE_MAIN_HR_INFORMATION],
    militaryRecord: [UPDATE_EMPLOYEE_MILITARY_INFO],
    familyMembers: [UPDATE_EMPLOYEE_FAMILY_INFORMATION],
    familyChildren: [UPDATE_EMPLOYEE_FAMILY_INFORMATION],
    maritalStatus: [UPDATE_EMPLOYEE_FAMILY_INFORMATION],
    requisitesInformation: [UPDATE_EMPLOYEE_REQUISITES_INFORMATION],
  },
};

const childrenAlias: Dictionary<any> = {
  Son: 'Son',
  Daughter: 'Daughter',
};

export const splitFamily = (family: any[] = []) => {
  const familyData: any[] = family || [];

  const data = {
    children: [],
    other: [],
  };

  familyData.forEach(member => {
    if (childrenAlias[member.relation]) {
      data.children.push(member as never);
    } else {
      data.other.push(member as never);
    }
  });

  return data;
};

export const mapSortingParameters = (parameters: { [s: string]: unknown } | ArrayLike<unknown>) => {
  const result: Dictionary<any> = {};

  Object.entries(parameters).forEach(([sortBy, sortOrder]) => {
    switch (sortBy) {
      case 'fullName': {
        result['nameEn.lastName'] = sortOrder;
        result['nameEn.firstName'] = sortOrder;
        break;
      }
      case 'positionName': {
        result['position.name'] = sortOrder;
        break;
      }
      case 'email': {
        result.workingEmail = sortOrder;
        break;
      }
      case 'resourcePoolName': {
        result['resourcePool.name'] = sortOrder;
        break;
      }
      case 'employeeId': {
        result.alias = sortOrder;
        break;
      }
      default: {
        result[sortBy] = sortOrder;
      }
    }
  });

  return result;
};

export const mapSearchFilters = (filters: Dictionary<any> = {}) => {
  const filterByCommonSkillExperience = filters['specification:commonSkillExperience'];

  const newFilters: Dictionary<any> = {
    archivedStatus: {
      eq: 'ACTIVE',
    },
    ...filters,
  };

  if (filterByCommonSkillExperience) {
    const filterByCommonSkillExperienceDuration = filters['specification:commonSkillExperience.experienceDuration'];
    if (filterByCommonSkillExperienceDuration) {
      delete newFilters['specification:commonSkillExperience.experienceDuration'];
    }

    newFilters[`specification:commonSkillExperience`] = {
      in: {
        commonSkillId: filterByCommonSkillExperience?.in[0],
        experienceDuration: filterByCommonSkillExperienceDuration?.in,
      },
    };
  }

  return newFilters;
};

export const RESOURCE_URL = '/employees';

const getIdentityDocumentNumber = (employeeData: { extendedInformation: { identityDocuments: any } }) => {
  const identityDocuments = employeeData?.extendedInformation?.identityDocuments;
  const documentTypes = Object.keys(DOCUMENTS);

  const document = documentTypes
    .map(type => identityDocuments?.find((doc: { type: string }) => doc.type === type))
    .find(doc => doc);

  return document ? document.number : null;
};

/**
 * Employee service.
 *
 * */
const useEmployeeService = () => {
  const { getUserAlias } = useAuthenticatedUserService();
  const { createMapper, updateMapper } = useEmployeeMapper();
  const { sendGetRequest, sendPutRequest } = useKernelApi();
  const { search: searchSuggestions, searchWithContractors } = useEmployeeSuggestionsService();
  const searchParams = useSearchParameters();

  const {
    create,
    update,
    getById: initialGetById,
    ...baseMethods
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const {
    search: searchTimelineItems,
    create: createTimelineItemBase,
    getTimelinePageById,
  } = useTimelineService({
    parentEntityUrl: `${RESOURCE_URL}/:id`,
    apiService: useKernelApi,
  });

  const createTimelineItem = async (employeeId: StringOrNumber, timelineEntityType: string, data: any) => {
    return createTimelineItemBase(employeeId, timelineEntityType, {
      ...data,
      employeeId,
    });
  };

  const search = ({ pageNumber = 0, sort = { 'nameEn.lastName': 'ASC' }, filter = {} } = {}) => {
    const request = {
      filter: mapSearchFilters(filter),
      sort: mapSortingParameters(sort),
      pageSize: 30,
      pageNumber,
    };

    return baseMethods.search(request);
  };

  const searchAll = ({ sort = { 'nameEn.lastName': 'ASC' }, filter = {} } = {}) => {
    const params = {
      filter: mapSearchFilters(filter),
      sort: mapSortingParameters(sort),
    };

    return baseMethods.searchAll(params);
  };

  const getCurrentEmployeeId = async (employeeId: StringOrNumber) => {
    const alias = await getUserAlias();
    return employeeId === MY_PAGE_PARAMETER ? alias : employeeId;
  };

  const getValidationSchema = () => Promise.resolve(EMPLOYEE_VALIDATION);

  const getCareerReviewValidationSchema = () => Promise.resolve(CAREER_REVIEW_VALIDATION);

  const checkIsNewEmployee = (state: string) => EMPLOYEE_STATE_KEYS.NEW === state;

  const getFullName = ({ firstName, lastName, maidenName }: UserName) => {
    return `${firstName} ${lastName} ${maidenName ? `(${maidenName})` : ''}`;
  };

  const getById = async (params?: string) => {
    const alias = await getUserAlias();
    const employeeData = await initialGetById((params || alias) as string);

    let documentType;
    if (employeeData.extendedInformation.passportData) {
      documentType = DOCUMENTS_ALIAS.PASSPORT;
    } else if (employeeData.extendedInformation.residencePermitData) {
      documentType = DOCUMENTS_ALIAS.RESIDENCE_PERMIT;
    } else if (employeeData.extendedInformation.peselData) {
      documentType = DOCUMENTS_ALIAS.PESEL;
    } else {
      documentType = undefined;
    }
    employeeData.extendedInformation.documentType = documentType;

    if (
      employeeData.employeeType === EMPLOYEE_TYPES_ALIAS.CONTRACTOR &&
      employeeData.initializationState === EMPLOYEE_STATE_KEYS.FINALIZED
    ) {
      employeeData.initializationState = null;
    }

    return employeeData;
  };

  const getForUpdate = async (id: StringOrNumber) => {
    const employeeData = await getById(id as string);

    const familyData = splitFamily(employeeData.extendedInformation.familyMembers);
    employeeData.extendedInformation.children = familyData.children;
    employeeData.extendedInformation.other = familyData.other;

    employeeData.azureId = searchParams.azureId || employeeData.azureId;
    employeeData.workingEmail = searchParams.workingEmail || employeeData.workingEmail;

    return employeeData;
  };

  const getCurrentEmployee = async () => {
    const employee = await getById();
    return employee;
  };

  const updateMentors = async (employeeId: StringOrNumber, rawRequest: FormFieldsData) => {
    const request = {
      mentorsIds: rawRequest.mentors ? rawRequest.mentors.map((object: any) => object.value) : [],
    };
    const currentAlias = await getCurrentEmployeeId(employeeId);

    const response = await sendPutRequest(`/employees/${currentAlias}/mentors`, request);
    return response.json();
  };

  const getMentors = async (employeeId: StringOrNumber) => {
    const currentAlias = await getCurrentEmployeeId(employeeId);
    const response = await sendGetRequest(`/employees/${currentAlias}/mentors`);
    return response.json();
  };

  const isWorkplaceLocationNotRemote = (location: string) => location !== EMPLOYEE_WORKPLACE_LOCATION_IDS.remoteOnly;

  /**
   * Returns employee ID by alias.
   *
   * @param {Nullable<string>} employeeAlias employee alias (ex: E1462487)
   * @returns {Promise<number>} employee ID
   */
  const getEmployeeCompanyId = async (employeeAlias: string) => {
    const employeeDto = await getById(employeeAlias);
    return employeeDto.companyId;
  };

  const getEmployeeName = async (employeeAlias: string) => {
    const employeeName = await sendGetRequest(`/employees/${employeeAlias}/name`);
    return employeeName.json();
  };

  return {
    ...baseMethods,
    create: (employee: any) => {
      return create(createMapper(employee));
    },
    update: (id: StringOrNumber, employee: any) => {
      return update(id, updateMapper(employee));
    },
    rawUpdate: update,
    getForUpdate,
    getCurrentEmployee,
    getCurrentEmployeeId,
    search,
    getValidationSchema,
    checkIsNewEmployee,
    getFullName,
    searchSuggestions,
    getById,
    searchTimelineItems,
    createTimelineItem,
    searchAll,
    getTimelinePageById,
    updateMentors,
    getMentors,
    isWorkplaceLocationNotRemote,
    getCareerReviewValidationSchema,
    getIdentityDocumentNumber,
    searchWithContractors,
    getEmployeeCompanyId,
    getEmployeeName,
  };
};

export default useEmployeeService;

import purchaseOrderValidation from 'financialAnalytic/purchaseOrder/createupdate/purchaseOrderValidation';
import { useFinanceApi } from 'api';
import useCrudService from 'shared/crud';
import { CONTENT_TYPE } from 'shared/api/const';
import useEmployeeService from 'erp/employee/employeeService';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import { cloneDeep } from 'lodash';
import { ResourceData } from 'shared/crud/baseCrudService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export const READ_PURCHASE_ORDER_LIST = 'COMPANY_READ_PURCHASE_ORDER';
export const READ_PURCHASE_ORDER = 'COMPANY_READ_PURCHASE_ORDER';
export const CREATE_PURCHASE_ORDER = 'COMPANY_CREATE_PURCHASE_ORDER';
export const UPDATE_PURCHASE_ORDER = 'COMPANY_UPDATE_PURCHASE_ORDER';
export const REJECT_PURCHASE_ORDER = 'COMPANY_REJECT_PURCHASE_ORDER';
export const CLOSE_PURCHASE_ORDER = 'COMPANY_CLOSE_PURCHASE_ORDER';
export const MARK_GOODS_AND_SERVICES_DELIVERED = 'COMPANY_MARK_GOODS_AND_SERVICES_DELIVERED';
export const MARK_GOODS_AND_SERVICES_DELIVERED_PARTIALLY = 'COMPANY_MARK_GOODS_AND_SERVICES_DELIVERED_PARTIALLY';
export const MARK_AS_DELIVERED_PURCHASE_ORDER = 'COMPANY_CLOSE_PURCHASE_ORDER';
export const LINK_INVOICE_TO_PURCHASE_ORDER = 'COMPANY_LINK_INVOICE_TO_PURCHASE_ORDER';
export const APPROVE_PURCHASE_ORDER = 'COMPANY_APPROVE_PURCHASE_ORDER';
export const PERFORM_MIGRATION = 'COMPANY_MIGRATE_QLDB_ENTITIES';
export const PURCHASE_ORDER_PATH = '/purchase-orders';
export const RESOURCE_URL = '/procurement/purchase-orders';

export const PURCHASE_ORDER_STATUSES = {
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  PARTIALLY_CLOSED: 'PARTIALLY_CLOSED',
  CLOSED: 'CLOSED',
};

export const PURCHASE_ORDER_STATUSES_OPTIONS = {
  [PURCHASE_ORDER_STATUSES.REJECTED]: 'Rejected',
  [PURCHASE_ORDER_STATUSES.APPROVED]: 'Approved',
  [PURCHASE_ORDER_STATUSES.PENDING]: 'Pending',
  [PURCHASE_ORDER_STATUSES.PARTIALLY_CLOSED]: 'Partially Closed',
  [PURCHASE_ORDER_STATUSES.CLOSED]: 'Closed',
};

export const GOODS_AND_SERVICES_DELIVERY_STATUS = {
  NOT_DELIVERED: 'NOT_DELIVERED',
  PARTIALLY_DELIVERED: 'PARTIALLY_DELIVERED',
  DELIVERED: 'DELIVERED',
};

export const GOODS_AND_SERVICES_DELIVERY_STATUS_OPTIONS = {
  [GOODS_AND_SERVICES_DELIVERY_STATUS.NOT_DELIVERED]: 'Not delivered',
  [GOODS_AND_SERVICES_DELIVERY_STATUS.PARTIALLY_DELIVERED]: 'Partially delivered',
  [GOODS_AND_SERVICES_DELIVERY_STATUS.DELIVERED]: 'Delivered',
};

export const PURCHASE_ORDER_TYPES = {
  STANDARD: 'STANDARD',
  BLANKET: 'BLANKET',
  CONTRACT: 'CONTRACT',
};

export const PURCHASE_ORDER_TYPES_OPTIONS = {
  [PURCHASE_ORDER_TYPES.STANDARD]: 'Standard',
  [PURCHASE_ORDER_TYPES.BLANKET]: 'Blanket',
  [PURCHASE_ORDER_TYPES.CONTRACT]: 'Contract',
};

const getFormattedPurchaseOrderFormData = ({ currency, purchases, requestorName, ...data }: any) => ({
  ...data,
  purchases: purchases.map((purchase: any) => ({
    ...purchase,
    cost: { ...purchase.cost, currency: currency === 'BYN' ? 'BYR' : currency },
  })),
});

const usePurchaseOrderService = () => {
  const { getFullName } = useEmployeeService();
  const { getEmployeeNameDataById } = useEmployeeNamesService();
  const { sendPostRequest } = useFinanceApi();

  const {
    create: initialCreate,
    update: initialUpdate,
    getById: initialGetById,
    search: baseSearch,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useFinanceApi,
  });

  const search = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter.title = {
        ct: searchString,
      };
    }

    return baseSearch(searchParams);
  };

  const getById = async (id: any, ignoreCache = false) => {
    const data = await initialGetById(id, ignoreCache);
    const requestorName = (await getEmployeeNameDataById(data.requestor)) || {};

    return {
      ...data,
      requestorName: requestorName.nameEn,
      requestorFullName: getFullName(requestorName.nameEn || {}),
    };
  };

  const getSensitiveDataByIds = async (ids: string[]) => {
    if (!ids.length) {
      return {};
    }

    const result = await sendPostRequest(`${RESOURCE_URL}/subtotals`, ids);

    return result.json();
  };

  const create = async (data: any) => {
    const formattedData = getFormattedPurchaseOrderFormData(data);

    return initialCreate(formattedData);
  };

  const update = async (id: StringOrNumber, data: FormFieldsData) => {
    const formattedData = getFormattedPurchaseOrderFormData(data);

    return initialUpdate(id, formattedData);
  };

  const rejectPurchaseOrder = async (id: string, rejectReason: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/reject`, rejectReason, CONTENT_TYPE.MULTIPART);
    return result.json();
  };

  const closePurchaseOrder = async (id: string, goodsAndServicesDeliveryDate?: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/close`, goodsAndServicesDeliveryDate || '');
    return result.json();
  };

  const markAsDeliveredInvoicePurchaseOrder = async (
    linkedInvoiceId: string,
    purchaseOrderId: string,
    {
      goodsAndServicesDeliveryDate,
      isPartialDelivery,
      partialDeliverySum,
    }: {
      goodsAndServicesDeliveryDate: string;
      isPartialDelivery: boolean;
      partialDeliverySum?: number;
    },
  ) => {
    const result = await sendPostRequest(
      `${RESOURCE_URL}/${purchaseOrderId}/linked-invoices/${linkedInvoiceId}/mark-as-delivered`,
      {
        goodsAndServicesDeliveryDate,
        isPartialDelivery,
        partialDeliverySum,
      },
    );

    return result.json();
  };

  const approvePurchaseOrder = async (id: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/approve`);
    return result.json();
  };

  const linkInvoiceToPurchaseOrder = async (purchaseOrderId: string, invoiceId: string) => {
    const result = await sendPostRequest(`/procurement/invoices/${invoiceId}/link/purchase-orders/${purchaseOrderId}`);
    return result.json();
  };

  const getAvailableToLinkPurchaseOrders = async (params: any) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/available-to-link`, { ...params, pageSize: 30 });
    return result.json();
  };

  const getAllAvailableToLinkPurchaseOrders = async (params: any) => {
    let totalPages = 0;
    let currentPage = 0;
    let result: any[] = [];

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await getAvailableToLinkPurchaseOrders({
        ...params,
        pageNumber: currentPage,
      });
      // eslint-disable-next-line no-await-in-loop

      currentPage += 1;
      totalPages = response.totalPages;
      result = [...result, ...(response.result?.filter((item: any) => !item.hasCompensation) || [])];
    } while (currentPage < totalPages);

    return result;
  };

  const runPurchaseOrderMigration = async (data: Dictionary<string>) => {
    return sendPostRequest(`${RESOURCE_URL}/migrate-subtotals`, data);
  };

  const runPurchaseOrderValidation = async (data: Dictionary<string>) => {
    return sendPostRequest(`${RESOURCE_URL}/validate-subtotals`, data);
  };

  const getValidationSchema = () => Promise.resolve(purchaseOrderValidation);

  return {
    ...baseCrud,
    create,
    update,
    getValidationSchema,
    rejectPurchaseOrder,
    getSensitiveDataByIds,
    markAsDeliveredInvoicePurchaseOrder,
    approvePurchaseOrder,
    closePurchaseOrder,
    getById,
    linkInvoiceToPurchaseOrder,
    getAvailableToLinkPurchaseOrders,
    getAllAvailableToLinkPurchaseOrders,
    search,
    runPurchaseOrderMigration,
    runPurchaseOrderValidation,
  };
};

export default usePurchaseOrderService;

export const EMPLOYEES_DASHBOARD_PATH = '/employees-dashboard';

export const EMPLOYEE_STATUS = {
  ACTIVE: 'ACTIVE',
  PROBATION: ' PROBATION',
  IN_DISMISSAL: 'IN_DISMISSAL',
  DISMISSAL: 'DISMISSAL',
  DISMISSED: 'DISMISSED',
  RELOCATION: 'RELOCATION',
};

export const MAP_EMPLOYEE_STATUS = {
  ACTIVE: 'Active',
  PROBATION: 'Probation',
  IN_DISMISSAL: 'In dismissal',
  DISMISSAL: 'Dismissal',
  DISMISSED: 'Dismissed',
  RELOCATION: 'Relocation',
};

export const EMPLOYEE_ZONES_ALIASES = {
  SAFE: 'SAFE',
  MONITORING: 'MONITORING',
  CRITICAL: 'CRITICAL',
  ESCALATION: 'ESCALATION',
};

export const EMPLOYEE_DASHBOARD_COLORS = {
  SAFE: 'var(--mui-palette-border-success)',
  MONITORING: 'var(--mui-palette-border-warning)',
  CRITICAL: 'var(--mui-palette-border-error)',
  ESCALATION: 'var(--mui-palette-border-error)',
};

export const EMPLOYEE_ZONES = {
  [EMPLOYEE_ZONES_ALIASES.SAFE]: 'Safe',
  [EMPLOYEE_ZONES_ALIASES.MONITORING]: 'Monitoring',
  [EMPLOYEE_ZONES_ALIASES.CRITICAL]: 'Critical',
  [EMPLOYEE_ZONES_ALIASES.ESCALATION]: 'Escalation',
};

export const RETENTION_RISK_ISSUE_STATUS = {
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
};

export const zoneStyles = {
  SAFE: {
    backgroundColor: 'var(--mui-palette-background-success)',
    color: EMPLOYEE_DASHBOARD_COLORS.SAFE,
  },
  MONITORING: {
    backgroundColor: 'var(--mui-palette-background-warning)',
    color: EMPLOYEE_DASHBOARD_COLORS.MONITORING,
  },
  CRITICAL: {
    backgroundColor: 'var(--mui-palette-background-error)',
    color: EMPLOYEE_DASHBOARD_COLORS.CRITICAL,
  },
  ESCALATION: {
    backgroundColor: 'var(--mui-palette-background-hardError)',
    color: EMPLOYEE_DASHBOARD_COLORS.ESCALATION,
  },
};

/* istanbul ignore file */
// libs
import React, { ReactElement } from 'react';
import Fade from 'uibuilder/Fade';
// components
import Message from 'shared/message/Message';
// styles
import styles from './CandidateTimeline.module.scss';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';
import { ResourceData } from 'shared/crud/baseCrudService';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import AddCandidateTimelineItemInput from './shared/input/AddCandidateTimelineItemInput';
import useCandidateNoteItem from './note/note/candidateNoteRegistry';
import useCandidateOfferItem from './offer/candidateOfferRegistry';

const DEFAULT_FUNCTION = () => {};

export const CANDIDATE_TIMELINE_MESSAGE_CONTEXT = 'CANDIDATE_TIMELINE_MESSAGE_CONTEXT';

interface TimelinePageLayoutProps {
  children?: React.ReactNode;
  filter?: React.ReactNode;
  hasAddForm?: boolean;
  additionalButtons?: ReactElement[];
}

const CandidateTimelinePageLayout = ({
  children,
  filter,
  hasAddForm = true,
  additionalButtons,
}: TimelinePageLayoutProps) => {
  const {
    addForm,
    addToList = DEFAULT_FUNCTION,
    createTimelineItemMethod = DEFAULT_FUNCTION,
    setAddForm = DEFAULT_FUNCTION,
    isEntryViewMode,
    reloadData,
    wrapItem = () => ({}),
  }: TimelineContextData = useListContext();
  const { data: parentContext } = useShowContext();

  const closeAddForm = () => setAddForm(null);

  const noteRegistry = useCandidateNoteItem();
  const offerRegistry = useCandidateOfferItem();

  return (
    <div>
      <div className={styles.candidate_timeline_page_top}>
        <Fade in={!!filter} timeout={20} className={styles.candidate_timeline_filter_icon}>
          <div>{isEntryViewMode ? null : filter}</div>
        </Fade>
        <div className={`timeline__page_top_block ${filter ? '' : 'mb-9'}`}>
          {hasAddForm && !addForm && (
            <AddCandidateTimelineItemInput
              registries={[noteRegistry, offerRegistry] as any}
              selectItemCallback={registry => {
                const { AddForm, source, urlPostfix, additionalAfterAddSubmit } = registry;

                const form = (
                  <AddForm
                    messageContext={CANDIDATE_TIMELINE_MESSAGE_CONTEXT}
                    onCancel={closeAddForm}
                    submitFormFunc={(data: ResourceData) => createTimelineItemMethod(urlPostfix, data)}
                    afterSubmit={{
                      execute: (data: TimelineItem) => {
                        addToList(wrapItem(data, source));
                        closeAddForm();
                        if (additionalAfterAddSubmit) {
                          additionalAfterAddSubmit(data, parentContext, reloadData);
                        }
                      },
                    }}
                  />
                );
                setAddForm(form);
              }}
            />
          )}
          {additionalButtons}
        </div>
      </div>
      <Message messageContext={CANDIDATE_TIMELINE_MESSAGE_CONTEXT} />
      <div className="page-content">{children}</div>
    </div>
  );
};

export default CandidateTimelinePageLayout;

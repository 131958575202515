import { useState, useEffect } from 'react';
import useEmployeeSuggestionsService from 'erp/employee/employeeSuggestionsService';
import useStorageService from 'shared/uibuilder/storageService';

const STORAGE_KEY = 'assing-employees-dashboard';
const VERSION = '1.0';

type EloyeesType = {
  alias: string;
  workingEmail: string;
  nameEn: {
    firstName: string;
    middleName: null;
    lastName: string;
    maidenName: null;
  };
  office: string;
  timezone: string;
};
const useEmployeesForAssingService = () => {
  const [employees, setEmployees] = useState<
    {
      name: string;
      alias: string;
    }[]
  >([]);
  const { searchAll } = useEmployeeSuggestionsService();

  const getEmployeesForAssing = async () => {
    const avaliableEmployees = (await searchAll({
      filter: {
        'specification:employeeDashboardAssigneeSpecification': {
          eq: true,
        },
      },
    })) as unknown as EloyeesType[];

    return [
      ...avaliableEmployees.map((employee: EloyeesType) => {
        return {
          name: `${employee?.nameEn?.firstName} ${employee?.nameEn?.lastName}`,
          alias: employee.alias,
        };
      }),
    ];
  };

  const { getDataFromStorage } = useStorageService(STORAGE_KEY, getEmployeesForAssing, VERSION);
  useEffect(() => {
    getDataFromStorage().then(storageParameters => setEmployees(storageParameters || []));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { employees };
};

export default useEmployeesForAssingService;

/* istanbul ignore file */
import React from 'react';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import { EmployeeLink } from 'erp/employee';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
import NameField from 'shared/uibuilder/field/NameField';
import useAsyncValue from 'shared/asyncHelper';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import InputLoader from 'shared/uibuilder/InputLoader';

const EmployeeLinkFieldWithNameLoading = ({ source, externalId, value, ...props }: FieldProps) => {
  const { getEmployeeNameDataById } = useEmployeeNamesService();
  const { getValue: getId } = useFieldHelper({ source });
  const id = externalId || value || getId();

  const employeeName = useAsyncValue(() => (id ? getEmployeeNameDataById(id) : Promise.resolve({})), [id]);

  const fieldValue =
    employeeName === null ? (
      <InputLoader />
    ) : (
      <EmployeeLink name={employeeName?.nameEn ? <NameField value={employeeName?.nameEn} /> : id} id={id} {...props} />
    );
  const displayedValue = id ? fieldValue : EMPTY_VALUE_INDICATOR;

  return <TextField value={displayedValue} {...props} />;
};

export default EmployeeLinkFieldWithNameLoading;

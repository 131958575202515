/* istanbul ignore file */
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useRecruitmentApi } from 'api';
import candidateNoteValidation from 'erp/candidate/Timeline/note/shared/form/candidateNoteValidation';

export const UPDATE_CANDIDATE_NOTE_PERMISSION = 'FE_UPDATE_CANDIDATE_NOTE';
export const CREATE_CANDIDATE_NOTE_PERMISSION = 'CREATE_NOTE';
export const READ_CANDIDATE_NOTES_PERMISSION = 'READ_CANDIDATE_NOTES';

const useCandidateNoteService = () => {
  const { update } = useBaseCrudService({
    listResourceUrl: '/candidates/notes',
    singleResourceUrl: '/candidates/notes/:id',
    apiService: useRecruitmentApi,
  });

  return {
    getValidationSchema: () => Promise.resolve(candidateNoteValidation),
    update,
  };
};

export default useCandidateNoteService;

/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import { Checkbox } from 'shared/uibuilder/form/input';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import PeopleManagerDropdown from 'erp/employee/shared/input/PeopleManagerDropdown';

const PeopleManagementSpecifySection = () => {
  const { data: formData, onChangeCallback, isDirty } = useFormContext();
  const assignedPeopleManagerAliases = formData?.assignedPeopleManagerAliases || [];
  return (
    <FormRow>
      <Checkbox
        source="isNeedToSpecifyPeopleManagers"
        label="Assign Specific People Managers"
        onChangeCallback={(formFields: FormFieldsData) => {
          const { isNeedToSpecifyPeopleManagers } = formFields;
          const isNeedToSpecify =
            !isDirty && assignedPeopleManagerAliases?.length > 0 ? true : isNeedToSpecifyPeopleManagers;

          const pplManagers = isNeedToSpecify ? assignedPeopleManagerAliases : [];
          if (onChangeCallback) {
            onChangeCallback({
              isNeedToSpecifyPeopleManagers: isNeedToSpecify,
              assignedPeopleManagerAliases: pplManagers,
            });
          }
        }}
      />
      <PeopleManagerDropdown
        source="assignedPeopleManagerAliases"
        label="People Managers"
        multiple
        disabled={(data: any) => !data?.isNeedToSpecifyPeopleManagers}
        helpText="If no managers are selected, all People Managers will be assigned by default."
      />
    </FormRow>
  );
};

export default PeopleManagementSpecifySection;

import React from 'react';
import { Stack, Typography } from '@mui/material';
import Tooltip from 'uibuilder/Tooltip';
import Box from 'uibuilder/Box';
import { RetentionRiskIssue } from '../../types';

const IssuesEmployeeSummaryContainer = ({ issues }: { issues: RetentionRiskIssue[] }) => {
  if (!issues || !issues.length) {
    return null;
  }

  const displayLimit = 2;
  const hasMoreIssues = issues.length > displayLimit;
  const displayedIssues = issues.slice(0, displayLimit);
  const remainingCount = issues.length - displayLimit;

  const tooltipContent = (
    <Box>
      {issues.map((issue, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box mb={2} key={index}>
          {issue.description}
        </Box>
      ))}
    </Box>
  );

  const content = (
    <Stack>
      {displayedIssues.map((issue, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography sx={{ fontSize: '10px', mb: 1 }} color="error" key={index}>
          {issue.description}
        </Typography>
      ))}
      {hasMoreIssues && (
        <Typography sx={{ fontSize: '10px' }} color="error">{`...and ${remainingCount} more`}</Typography>
      )}
    </Stack>
  );

  return hasMoreIssues ? (
    <Tooltip title={tooltipContent} arrow placement="top">
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default IssuesEmployeeSummaryContainer;

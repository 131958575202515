/* istanbul ignore file */
import React from 'react';
import RunMigrationButton from 'financialAnalytic/shared/button/RunMigrationButton';
import useInvoiceService, { PERFORM_MIGRATION } from '../../useInvoiceService';

const LOCAL_STORAGE_KEY = 'invoice-export-data';

const RunInvoiceValidationButton = () => {
  const { runInvoiceValidation } = useInvoiceService();

  return (
    <RunMigrationButton
      displayMessage="Please, Fill out inputs below"
      localStorageKey={LOCAL_STORAGE_KEY}
      submitMethod={runInvoiceValidation}
      permissionToCheck={PERFORM_MIGRATION}
    >
      Run Validation
    </RunMigrationButton>
  );
};

export default RunInvoiceValidationButton;

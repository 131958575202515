import React, { useState } from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import { useDateService } from 'shared/uibuilder/dateService';
import HealthcheckStatus from './HealthcheckStatus';
import HealthcheckButton from './HealthcheckButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { DashboardEmployee } from '../../types';
import useEmployeesDashboardService from '../../useEmployeesDashboardService';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

interface HealthcheckResponse {
  lastHealthcheckDate: Nullable<string>;
}

const Healthcheck = () => {
  const { data, setData } = useShowContext();
  const { getToday } = useDateService();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [healthcheckDate, setHealthcheckDate] = useState<Nullable<string>>(data.retentionRiskLastHealthcheck);
  const { retentionRiskId: riskId } = data.getData() as DashboardEmployee;
  const { updateHealthcheck } = useEmployeesDashboardService();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDateChange = (date: FormFieldsData) => {
    setHealthcheckDate(date.healthcheckDate);
  };

  const handleSubmit = () => {
    if (!healthcheckDate) {
      return;
    }

    updateHealthcheck(riskId, { date: healthcheckDate })
      .then((response: HealthcheckResponse) => {
        setData!({
          ...data.getData(),
          retentionRiskLastHealthcheck: response.lastHealthcheckDate,
        });
      })
      .then(() => toggleModal());
  };

  return (
    <>
      <Box>
        <HealthcheckStatus iconSx={{ fontSize: 11 }} textSx={{ fontSize: 12 }} />
        <HealthcheckButton
          lastHealthcheckDate={healthcheckDate}
          onClick={toggleModal}
          style={{ fontSize: 11, marginTop: 1 }}
        />
      </Box>
      <ModalWindow
        buttons={
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        }
        hasCancelButton
        isOpen={isModalOpen}
        onToggle={toggleModal}
      >
        <DateInput
          source="healthcheckDate"
          placeholder="Healthcheck date"
          label=""
          value={healthcheckDate}
          onChangeCallback={handleDateChange}
          maxDate={getToday()}
        />
      </ModalWindow>
    </>
  );
};

export default Healthcheck;

import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import LabelStatus from '../../show/shared/LabelStatus';
import TargetHireDateInput from '../../shared/inputs/TargetHireDateInput';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { HIRING_MODE } from '../../../hiring/useHiringService';
import FormRow from 'shared/layout/form/FormRow';
import DateField from 'shared/uibuilder/field/DateField';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import { ENGLISH_LEVEL_SHORT_OPTIONS, POSITION_STATUS } from '../../constants';
import InputList from 'shared/uibuilder/form/input/InputList/InputList';
import WorkingConditionsInputs from '../../shared/inputs/WorkingConditionsInputs';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import BigFormRow from 'shared/layout/form/BigFormRow';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';
import useCommonSkillsService from 'erp/candidate/commonskills/commonSkillsService';
import ProjectDescriptionInputs from '../../shared/inputs/ProjectDescriptionInputs';
import NoteAttachmentUploaderInput from 'artifact/shared/input/NoteAttachmentUploaderInput';
import PositionsInputsListWrapper from '../../shared/inputs/positions/PositionsInputsListWrapper';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import VacancyWysiwygInput from '../../../vacancy/shared/input/VacancyWysiwygInput';

const UpdateVacancyForm = () => {
  const { searchByName } = useCommonSkillsService();
  const { data: showData } = useShowContext();
  const data = showData.getData();

  return (
    <>
      <FormSection
        titleId="main"
        title={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography variant="h4">
              {data.specializationName} {data.competencyRange}
            </Typography>
            <LabelStatus status={data.status} />
          </Box>
        }
      >
        <FormRow colXl={3}>
          <TextInput label="Title" source="title" isRequired />
          <DateField source="expirationDate" label="Expiration" />
        </FormRow>

        <FormRow colXl={3}>
          <TargetHireDateInput />
          <EnumDropdown
            tooltip={
              <>
                Active hiring mode means that a new position is available and the person is needed to fill it.
                <br />
                Bar raiser means that no additional positions are available, but the staffing coordinator will replace
                low-performers with the hired person.
              </>
            }
            options={HIRING_MODE}
            source="hiringMode"
            label="Hiring Mode"
            placeholder="Select Mode"
          />
        </FormRow>

        <FormRow colXl={3}>
          <EmployeeInput
            mapResults={(employee: any) => ({
              id: employee.alias,
              text: `${employee.nameEn.firstName} ${employee.nameEn.lastName}`,
            })}
            nameSource="responsiblePersonName"
            source="responsiblePerson"
            label="DRI"
          />
          <EmployeeInput
            mapResults={(employee: any) => ({
              id: employee.alias,
              text: `${employee.nameEn.firstName} ${employee.nameEn.lastName}`,
            })}
            nameSource="staffingCoordinatorName"
            source="staffingCoordinator"
            label="Coordinator"
          />
        </FormRow>
      </FormSection>

      <FormSection title="Positions">
        <BigFormRow>
          <InputList
            maxFields={100}
            addText="Add Position"
            minFields={data.positions.length}
            source="positions"
            defaultValue={{
              status: POSITION_STATUS.OPEN,
            }}
            disabledRemove={value => !!value.id}
            inputTemplate={PositionsInputsListWrapper}
          />
        </BigFormRow>
      </FormSection>

      <FormSection title="Working Conditions">
        <ClearAfterUnmountWrapper>
          <InputList
            minFields={1}
            source="workingConditions"
            addText="Add location"
            maxFields={10}
            inputTemplate={(props: any) => WorkingConditionsInputs({ isUpdateForm: true, props })}
          />
        </ClearAfterUnmountWrapper>
      </FormSection>

      <FormSection title="Project Description">
        <ClearAfterUnmountWrapper>
          <InputList source="projects" addText="Add project" maxFields={10} inputTemplate={ProjectDescriptionInputs} />
        </ClearAfterUnmountWrapper>
      </FormSection>

      <FormSection title="Responsibilities*">
        <BigFormRow>
          <VacancyWysiwygInput source="responsibilities" label="" />
        </BigFormRow>
      </FormSection>

      <FormSection title="Mandatory Requirements">
        <BigFormRow>
          <VacancyWysiwygInput source="mandatoryRequirements.description" label="" />
        </BigFormRow>

        <MediumFormRow>
          <SkillsInput
            source="mandatoryRequirements.skills"
            loadSuggestionsMethod={searchByName}
            label="Skills"
            helpText="Required skills for the Vacancy. These skills will be used for Candidate matching purposes and will not be publicly displayed."
          />
        </MediumFormRow>

        <FormRow>
          <NumberInput source="mandatoryRequirements.requiredExperienceInYears" label="Required Experience (years)" />

          <EnumDropdown
            source="mandatoryRequirements.englishLevel"
            label="English Level"
            options={ENGLISH_LEVEL_SHORT_OPTIONS}
            placeholder="Choose English Level"
          />
        </FormRow>
      </FormSection>

      <FormSection title="Optional Requirements">
        <BigFormRow>
          <VacancyWysiwygInput source="optionalRequirements.description" label="" />
        </BigFormRow>

        <MediumFormRow>
          <SkillsInput
            source="optionalRequirements.skills"
            loadSuggestionsMethod={searchByName}
            label="Skills:"
            helpText="Optional skills for the Vacancy. These skills will be used for Candidate matching purposes and will not be publicly displayed."
          />
        </MediumFormRow>
      </FormSection>

      <FormSection title="Notes">
        <BigFormRow>
          <VacancyWysiwygInput source="notes.description" label="" />
        </BigFormRow>
        <FormRow>
          <NoteAttachmentUploaderInput label="Attachments" source="notes.attachmentIds" />
        </FormRow>
      </FormSection>
    </>
  );
};

export default UpdateVacancyForm;

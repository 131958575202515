/* istanbul ignore file */
import React from 'react';
import RunMigrationButton from 'financialAnalytic/shared/button/RunMigrationButton';
import usePurchaseOrderService, { PERFORM_MIGRATION } from '../../usePurchaseOrderService';

const LOCAL_STORAGE_KEY = 'purchase-export-data';

const RunInvoiceMigrationButton = () => {
  const { runPurchaseOrderMigration } = usePurchaseOrderService();

  return (
    <RunMigrationButton
      displayMessage="Please, Fill out inputs below"
      localStorageKey={LOCAL_STORAGE_KEY}
      submitMethod={runPurchaseOrderMigration}
      permissionToCheck={PERFORM_MIGRATION}
    >
      Run Migration
    </RunMigrationButton>
  );
};

export default RunInvoiceMigrationButton;

/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

export const ANYWHERE = { name: 'Anywhere', code: 'ANYWHERE', emoji: '🇺🇳' };
export const BELARUS = { name: 'Belarus', code: 'BY', emoji: '🇧🇾' };

export const COUNTRIES = [
  { name: 'Unknown', code: 'UN', emoji: '🇺🇳' },
  { name: 'United States', code: 'US', emoji: '🇺🇸' },
  { name: 'Afghanistan', code: 'AF', emoji: '🇦🇫' },
  { name: 'Åland Islands', code: 'AX', emoji: '🇦🇽' },
  { name: 'Albania', code: 'AL', emoji: '🇦🇱' },
  { name: 'Algeria', code: 'DZ', emoji: '🇩🇿' },
  { name: 'American Samoa', code: 'AS', emoji: '🇦🇸' },
  { name: 'Andorra', code: 'AD', emoji: '🇦🇩' },
  { name: 'Angola', code: 'AO', emoji: '🇦🇴' },
  { name: 'Anguilla', code: 'AI', emoji: '🇦🇮' },
  { name: 'Antarctica', code: 'AQ', emoji: '🇦🇶' },
  { name: 'Antigua and Barbuda', code: 'AG', emoji: '🇦🇬' },
  { name: 'Argentina', code: 'AR', emoji: '🇦🇷' },
  { name: 'Armenia', code: 'AM', emoji: '🇦🇲' },
  { name: 'Aruba', code: 'AW', emoji: '🇦🇼' },
  { name: 'Australia', code: 'AU', emoji: '🇦🇺' },
  { name: 'Austria', code: 'AT', emoji: '🇦🇹' },
  { name: 'Azerbaijan', code: 'AZ', emoji: '🇦🇿' },
  { name: 'Bahamas', code: 'BS', emoji: '🇧🇸' },
  { name: 'Bahrain', code: 'BH', emoji: '🇧🇭' },
  { name: 'Bangladesh', code: 'BD', emoji: '🇧🇩' },
  { name: 'Barbados', code: 'BB', emoji: '🇧🇧' },
  { ...BELARUS },
  { name: 'Belgium', code: 'BE', emoji: '🇧🇪' },
  { name: 'Belize', code: 'BZ', emoji: '🇧🇿' },
  { name: 'Benin', code: 'BJ', emoji: '🇧🇯' },
  { name: 'Bermuda', code: 'BM', emoji: '🇧🇲' },
  { name: 'Bhutan', code: 'BT', emoji: '🇧🇹' },
  { name: 'Bolivia', code: 'BO', emoji: '🇧🇴' },
  { name: 'Bosnia and Herzegovina', code: 'BA', emoji: '🇧🇦' },
  { name: 'Botswana', code: 'BW', emoji: '🇧🇼' },
  { name: 'Bouvet Island', code: 'BV', emoji: '🇧🇻' },
  { name: 'Brazil', code: 'BR', emoji: '🇧🇷' },
  { name: 'British Indian Ocean Territory', code: 'IO', emoji: '🇮🇴' },
  { name: 'Brunei Darussalam', code: 'BN', emoji: '🇧🇳' },
  { name: 'Bulgaria', code: 'BG', emoji: '🇧🇬' },
  { name: 'Burkina Faso', code: 'BF', emoji: '🇧🇫' },
  { name: 'Burundi', code: 'BI', emoji: '🇧🇮' },
  { name: 'Cambodia', code: 'KH', emoji: '🇰🇭' },
  { name: 'Cameroon', code: 'CM', emoji: '🇨🇲' },
  { name: 'Canada', code: 'CA', emoji: '🇨🇦' },
  { name: 'Cape Verde', code: 'CV', emoji: '🇨🇻' },
  { name: 'Cayman Islands', code: 'KY', emoji: '🇰🇾' },
  { name: 'Central African Republic', code: 'CF', emoji: '🇨🇫' },
  { name: 'Chad', code: 'TD', emoji: '🇹🇩' },
  { name: 'Chile', code: 'CL', emoji: '🇨🇱' },
  { name: 'China', code: 'CN', emoji: '🇨🇳' },
  { name: 'Christmas Island', code: 'CX', emoji: '🇨🇽' },
  { name: 'Cocos (Keeling) Islands', code: 'CC', emoji: '🇨🇨' },
  { name: 'Colombia', code: 'CO', emoji: '🇨🇴' },
  { name: 'Comoros', code: 'KM', emoji: '🇰🇲' },
  { name: 'Congo', code: 'CG', emoji: '🇨🇬' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD', emoji: '🇨🇩' },
  { name: 'Cook Islands', code: 'CK', emoji: '🇨🇰' },
  { name: 'Costa Rica', code: 'CR', emoji: '🇨🇷' },
  { name: "Cote D'Ivoire", code: 'CI', emoji: '🇨🇮' },
  { name: 'Croatia', code: 'HR', emoji: '🇭🇷' },
  { name: 'Cuba', code: 'CU', emoji: '🇨🇺' },
  { name: 'Cyprus', code: 'CY', emoji: '🇨🇾' },
  { name: 'Czech Republic', code: 'CZ', emoji: '🇨🇿' },
  { name: 'Denmark', code: 'DK', emoji: '🇩🇰' },
  { name: 'Djibouti', code: 'DJ', emoji: '🇩🇯' },
  { name: 'Dominica', code: 'DM', emoji: '🇩🇲' },
  { name: 'Dominican Republic', code: 'DO', emoji: '🇩🇴' },
  { name: 'Ecuador', code: 'EC', emoji: '🇪🇨' },
  { name: 'Egypt', code: 'EG', emoji: '🇪🇬' },
  { name: 'El Salvador', code: 'SV', emoji: '🇸🇻' },
  { name: 'Equatorial Guinea', code: 'GQ', emoji: '🇬🇶' },
  { name: 'Eritrea', code: 'ER', emoji: '🇪🇷' },
  { name: 'Estonia', code: 'EE', emoji: '🇪🇪' },
  { name: 'Ethiopia', code: 'ET', emoji: '🇪🇹' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', emoji: '🇫🇰' },
  { name: 'Faroe Islands', code: 'FO', emoji: '🇫🇴' },
  { name: 'Fiji', code: 'FJ', emoji: '🇫🇯' },
  { name: 'Finland', code: 'FI', emoji: '🇫🇮' },
  { name: 'France', code: 'FR', emoji: '🇫🇷' },
  { name: 'French Guiana', code: 'GF', emoji: '🇬🇫' },
  { name: 'French Polynesia', code: 'PF', emoji: '🇵🇫' },
  { name: 'French Southern Territories', code: 'TF', emoji: '🇹🇫' },
  { name: 'Gabon', code: 'GA', emoji: '🇬🇦' },
  { name: 'Gambia', code: 'GM', emoji: '🇬🇲' },
  { name: 'Georgia', code: 'GE', emoji: '🇬🇪' },
  { name: 'Germany', code: 'DE', emoji: '🇩🇪' },
  { name: 'Ghana', code: 'GH', emoji: '🇬🇭' },
  { name: 'Gibraltar', code: 'GI', emoji: '🇬🇮' },
  { name: 'Greece', code: 'GR', emoji: '🇬🇷' },
  { name: 'Greenland', code: 'GL', emoji: '🇬🇱' },
  { name: 'Grenada', code: 'GD', emoji: '🇬🇩' },
  { name: 'Guadeloupe', code: 'GP', emoji: '🇬🇵' },
  { name: 'Guam', code: 'GU', emoji: '🇬🇺' },
  { name: 'Guatemala', code: 'GT', emoji: '🇬🇹' },
  { name: 'Guernsey', code: 'GG', emoji: '🇬🇬' },
  { name: 'Guinea', code: 'GN', emoji: '🇬🇳' },
  { name: 'Guinea-Bissau', code: 'GW', emoji: '🇬🇼' },
  { name: 'Guyana', code: 'GY', emoji: '🇬🇾' },
  { name: 'Haiti', code: 'HT', emoji: '🇭🇹' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM', emoji: '🇭🇲' },
  { name: 'Holy See (Vatican City State)', code: 'VA', emoji: '🇻🇦' },
  { name: 'Honduras', code: 'HN', emoji: '🇭🇳' },
  { name: 'Hong Kong', code: 'HK', emoji: '🇭🇰' },
  { name: 'Hungary', code: 'HU', emoji: '🇭🇺' },
  { name: 'Iceland', code: 'IS', emoji: '🇮🇸' },
  { name: 'India', code: 'IN', emoji: '🇮🇳' },
  { name: 'Indonesia', code: 'ID', emoji: '🇮🇩' },
  { name: 'Iran, Islamic Republic Of', code: 'IR', emoji: '🇮🇷' },
  { name: 'Iraq', code: 'IQ', emoji: '🇮🇶' },
  { name: 'Ireland', code: 'IE', emoji: '🇮🇪' },
  { name: 'Isle of Man', code: 'IM', emoji: '🇮🇲' },
  { name: 'Israel', code: 'IL', emoji: '🇮🇱' },
  { name: 'Italy', code: 'IT', emoji: '🇮🇹' },
  { name: 'Jamaica', code: 'JM', emoji: '🇯🇲' },
  { name: 'Japan', code: 'JP', emoji: '🇯🇵' },
  { name: 'Jersey', code: 'JE', emoji: '🇯🇪' },
  { name: 'Jordan', code: 'JO', emoji: '🇯🇴' },
  { name: 'Kazakhstan', code: 'KZ', emoji: '🇰🇿' },
  { name: 'Kenya', code: 'KE', emoji: '🇰🇪' },
  { name: 'Kiribati', code: 'KI', emoji: '🇰🇮' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP', emoji: '🇰🇵' },
  { name: 'Korea, Republic of', code: 'KR', emoji: '🇰🇷' },
  { name: 'Kuwait', code: 'KW', emoji: '🇰🇼' },
  { name: 'Kyrgyzstan', code: 'KG', emoji: '🇰🇬' },
  { name: "Lao People'S Democratic Republic", code: 'LA', emoji: '🇱🇦' },
  { name: 'Latvia', code: 'LV', emoji: '🇱🇻' },
  { name: 'Lebanon', code: 'LB', emoji: '🇱🇧' },
  { name: 'Lesotho', code: 'LS', emoji: '🇱🇸' },
  { name: 'Liberia', code: 'LR', emoji: '🇱🇷' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', emoji: '🇱🇾' },
  { name: 'Liechtenstein', code: 'LI', emoji: '🇱🇮' },
  { name: 'Lithuania', code: 'LT', emoji: '🇱🇹' },
  { name: 'Luxembourg', code: 'LU', emoji: '🇱🇺' },
  { name: 'Macao', code: 'MO', emoji: '🇲🇴' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK', emoji: '🇲🇰' },
  { name: 'Madagascar', code: 'MG', emoji: '🇲🇬' },
  { name: 'Malawi', code: 'MW', emoji: '🇲🇼' },
  { name: 'Malaysia', code: 'MY', emoji: '🇲🇾' },
  { name: 'Maldives', code: 'MV', emoji: '🇲🇻' },
  { name: 'Mali', code: 'ML', emoji: '🇲🇱' },
  { name: 'Malta', code: 'MT', emoji: '🇲🇹' },
  { name: 'Marshall Islands', code: 'MH', emoji: '🇲🇭' },
  { name: 'Martinique', code: 'MQ', emoji: '🇲🇶' },
  { name: 'Mauritania', code: 'MR', emoji: '🇲🇷' },
  { name: 'Mauritius', code: 'MU', emoji: '🇲🇺' },
  { name: 'Mayotte', code: 'YT', emoji: '🇾🇹' },
  { name: 'Mexico', code: 'MX', emoji: '🇲🇽' },
  { name: 'Micronesia, Federated States of', code: 'FM', emoji: '🇫🇲' },
  { name: 'Moldova, Republic of', code: 'MD', emoji: '🇲🇩' },
  { name: 'Monaco', code: 'MC', emoji: '🇲🇨' },
  { name: 'Mongolia', code: 'MN', emoji: '🇲🇳' },
  { name: 'Montserrat', code: 'MS', emoji: '🇲🇸' },
  { name: 'Morocco', code: 'MA', emoji: '🇲🇦' },
  { name: 'Mozambique', code: 'MZ', emoji: '🇲🇿' },
  { name: 'Myanmar', code: 'MM', emoji: '🇲🇲' },
  { name: 'Namibia', code: 'NA', emoji: '🇳🇦' },
  { name: 'Nauru', code: 'NR', emoji: '🇳🇷' },
  { name: 'Nepal', code: 'NP', emoji: '🇳🇵' },
  { name: 'Netherlands', code: 'NL', emoji: '🇳🇱' },
  { name: 'Netherlands Antilles', code: 'AN', emoji: '🇺🇳' },
  { name: 'New Caledonia', code: 'NC', emoji: '🇳🇨' },
  { name: 'New Zealand', code: 'NZ', emoji: '🇳🇿' },
  { name: 'Nicaragua', code: 'NI', emoji: '🇳🇮' },
  { name: 'Niger', code: 'NE', emoji: '🇳🇪' },
  { name: 'Nigeria', code: 'NG', emoji: '🇳🇬' },
  { name: 'Niue', code: 'NU', emoji: '🇳🇺' },
  { name: 'Norfolk Island', code: 'NF', emoji: '🇳🇫' },
  { name: 'Northern Mariana Islands', code: 'MP', emoji: '🇲🇵' },
  { name: 'Norway', code: 'NO', emoji: '🇳🇴' },
  { name: 'Oman', code: 'OM', emoji: '🇴🇲' },
  { name: 'Pakistan', code: 'PK', emoji: '🇵🇰' },
  { name: 'Palau', code: 'PW', emoji: '🇵🇼' },
  { name: 'Palestinian Territory, Occupied', code: 'PS', emoji: '🇵🇸' },
  { name: 'Panama', code: 'PA', emoji: '🇵🇦' },
  { name: 'Papua New Guinea', code: 'PG', emoji: '🇵🇬' },
  { name: 'Paraguay', code: 'PY', emoji: '🇵🇾' },
  { name: 'Peru', code: 'PE', emoji: '🇵🇪' },
  { name: 'Philippines', code: 'PH', emoji: '🇵🇭' },
  { name: 'Pitcairn', code: 'PN', emoji: '🇵🇳' },
  { name: 'Poland', code: 'PL', emoji: '🇵🇱' },
  { name: 'Portugal', code: 'PT', emoji: '🇵🇹' },
  { name: 'Puerto Rico', code: 'PR', emoji: '🇵🇷' },
  { name: 'Qatar', code: 'QA', emoji: '🇶🇦' },
  { name: 'Reunion', code: 'RE', emoji: '🇷🇪' },
  { name: 'Romania', code: 'RO', emoji: '🇷🇴' },
  { name: 'Russian Federation', code: 'RU', emoji: '🇷🇺' },
  { name: 'Rwanda', code: 'RW', emoji: '🇷🇼' },
  { name: 'Saint Helena', code: 'SH', emoji: '🇸🇭' },
  { name: 'Saint Kitts and Nevis', code: 'KN', emoji: '🇰🇳' },
  { name: 'Saint Lucia', code: 'LC', emoji: '🇱🇨' },
  { name: 'Saint Pierre and Miquelon', code: 'PM', emoji: '🇵🇲' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', emoji: '🇻🇨' },
  { name: 'Samoa', code: 'WS', emoji: '🇼🇸' },
  { name: 'San Marino', code: 'SM', emoji: '🇸🇲' },
  { name: 'Sao Tome and Principe', code: 'ST', emoji: '🇸🇹' },
  { name: 'Saudi Arabia', code: 'SA', emoji: '🇸🇦' },
  { name: 'Senegal', code: 'SN', emoji: '🇸🇳' },
  { name: 'Serbia and Montenegro', code: 'CS', emoji: '🇺🇳' },
  { name: 'Seychelles', code: 'SC', emoji: '🇸🇨' },
  { name: 'Sierra Leone', code: 'SL', emoji: '🇸🇱' },
  { name: 'Singapore', code: 'SG', emoji: '🇸🇬' },
  { name: 'Slovakia', code: 'SK', emoji: '🇸🇰' },
  { name: 'Slovenia', code: 'SI', emoji: '🇸🇮' },
  { name: 'Solomon Islands', code: 'SB', emoji: '🇸🇧' },
  { name: 'Somalia', code: 'SO', emoji: '🇸🇴' },
  { name: 'South Africa', code: 'ZA', emoji: '🇿🇦' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS', emoji: '🇬🇸' },
  { name: 'Spain', code: 'ES', emoji: '🇪🇸' },
  { name: 'Sri Lanka', code: 'LK', emoji: '🇱🇰' },
  { name: 'Sudan', code: 'SD', emoji: '🇸🇩' },
  { name: 'Suriname', code: 'SR', emoji: '🇸🇷' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', emoji: '🇸🇯' },
  { name: 'Swaziland', code: 'SZ', emoji: '🇸🇿' },
  { name: 'Sweden', code: 'SE', emoji: '🇸🇪' },
  { name: 'Switzerland', code: 'CH', emoji: '🇨🇭' },
  { name: 'Syrian Arab Republic', code: 'SY', emoji: '🇸🇾' },
  { name: 'Taiwan, Province of China', code: 'TW', emoji: '🇹🇼' },
  { name: 'Tajikistan', code: 'TJ', emoji: '🇹🇯' },
  { name: 'Tanzania, United Republic of', code: 'TZ', emoji: '🇹🇿' },
  { name: 'Thailand', code: 'TH', emoji: '🇹🇭' },
  { name: 'Timor-Leste', code: 'TL', emoji: '🇹🇱' },
  { name: 'Togo', code: 'TG', emoji: '🇹🇬' },
  { name: 'Tokelau', code: 'TK', emoji: '🇹🇰' },
  { name: 'Tonga', code: 'TO', emoji: '🇹🇴' },
  { name: 'Trinidad and Tobago', code: 'TT', emoji: '🇹🇹' },
  { name: 'Tunisia', code: 'TN', emoji: '🇹🇳' },
  { name: 'Turkey', code: 'TR', emoji: '🇹🇷' },
  { name: 'Turkmenistan', code: 'TM', emoji: '🇹🇲' },
  { name: 'Turks and Caicos Islands', code: 'TC', emoji: '🇹🇨' },
  { name: 'Tuvalu', code: 'TV', emoji: '🇹🇻' },
  { name: 'Uganda', code: 'UG', emoji: '🇺🇬' },
  { name: 'Ukraine', code: 'UA', emoji: '🇺🇦' },
  { name: 'United Arab Emirates', code: 'AE', emoji: '🇦🇪' },
  { name: 'United Kingdom', code: 'GB', emoji: '🇬🇧' },
  { name: 'United States Minor Outlying Islands', code: 'UM', emoji: '🇺🇲' },
  { name: 'Uruguay', code: 'UY', emoji: '🇺🇾' },
  { name: 'Uzbekistan', code: 'UZ', emoji: '🇺🇿' },
  { name: 'Vanuatu', code: 'VU', emoji: '🇻🇺' },
  { name: 'Venezuela', code: 'VE', emoji: '🇻🇪' },
  { name: 'Viet Nam', code: 'VN', emoji: '🇻🇳' },
  { name: 'Virgin Islands, British', code: 'VG', emoji: '🇻🇬' },
  { name: 'Virgin Islands, U.S.', code: 'VI', emoji: '🇻🇮' },
  { name: 'Wallis and Futuna', code: 'WF', emoji: '🇼🇫' },
  { name: 'Western Sahara', code: 'EH', emoji: '🇪🇭' },
  { name: 'Yemen', code: 'YE', emoji: '🇾🇪' },
  { name: 'Zambia', code: 'ZM', emoji: '🇿🇲' },
  { name: 'Zimbabwe', code: 'ZW', emoji: '🇿🇼' },
];

export const EUROPEAN_COUNTRIES_NAMES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
];

/**
 * Component is used to display dropdown with US states.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CountryDropdown = (props: DefaultDropdownProps) => {
  return (
    <Dropdown
      {...props}
      placeholder="Select Country"
      options={COUNTRIES.map(state => ({
        value: state.name,
        label: state.name,
      }))}
    />
  );
};

export default CountryDropdown;

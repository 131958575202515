import React from 'react';
import TimelineRecordAuthor from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordAuthor';
import { HtmlField } from 'shared/uibuilder/field';
import Tooltip from 'uibuilder/Tooltip';
import SmallLoader from 'shared/uibuilder/SmallLoader';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import ShowDate from 'shared/uibuilder/ShowDate';
import Box from 'uibuilder/Box';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import { Avatar, Card, CardContent, Divider, IconButton, Popover } from '@mui/material';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { Candidate } from '../../types';
import Typography from 'uibuilder/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHorizOutlined';
import CopyInterviewSchedulerLink from '../buttons/CopyInterview/CopyInterviewSchedulerLink';
import { Stages } from '../../constants';

const NOTE_WORDS_LIMIT = 130;

interface CandidateMenuProps {
  candidateDetails: Candidate | null;
  candidatePhoto: string;
  isLoading: boolean;
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  currentApplicationId: number;
  currentStage: Stages;
}

const NotesField = ({ noteText, href }: { noteText: string; href: string }) => {
  const showMoreIcon = noteText.length > NOTE_WORDS_LIMIT;

  return (
    <Box
      sx={{
        position: 'relative',
        ...(showMoreIcon && {
          pb: '35px',
        }),
      }}
    >
      <Typography
        variant="body1"
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 3,
          '& p': {
            my: 1,
          },
        }}
      >
        <p>
          <span>
            <HtmlField value={noteText} />
          </span>
          <span
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
          >
            {showMoreIcon && (
              <Tooltip title="Click to view more">
                <IconButton aria-label="Click to view more" href={href}>
                  <MoreHorizIcon />
                </IconButton>
              </Tooltip>
            )}
          </span>
        </p>
      </Typography>
    </Box>
  );
};

const CandidateMenu: React.FC<CandidateMenuProps> = ({
  candidateDetails,
  candidatePhoto,
  isLoading,
  open,
  handleClose,
  anchorEl,
  currentApplicationId,
  currentStage,
}) => {
  const { getSingleEntityUrl } = useCandidateUrl();

  return (
    <Popover
      data-hj-suppress
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      slotProps={{
        paper: {
          style: {
            minWidth: 100,
            minHeight: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      }}
    >
      <Box>
        {isLoading ? (
          <SmallLoader />
        ) : (
          <Card
            sx={{
              width: 'min-content',
              minWidth: 300,
              maxWidth: 400,
              borderRadius: 2,
              boxShadow: 3,
              px: 2,
              py: 1,
            }}
          >
            <Box display="flex" alignItems="start" flexDirection="column">
              <Box display="flex" alignItems="center" width="100%">
                <Avatar
                  src={candidatePhoto}
                  alt="Candidate"
                  sx={{
                    width: 75,
                    height: candidatePhoto ? 100 : 75,
                    mr: 2,
                    borderRadius: candidatePhoto ? 'unset' : '50%',
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  <Box display="flex" justifyContent="start">
                    <Tooltip title={candidateDetails?.contacts?.LinkedIn ? '' : 'No LinkedIn provided'}>
                      <IconButton
                        href={candidateDetails?.contacts?.LinkedIn || ''}
                        target="_blank"
                        aria-label="LinkedIn"
                      >
                        <LinkedInIcon sx={{ color: '#4360E5' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={candidateDetails?.contacts?.personalEmail ? '' : 'No email provided'}>
                      <IconButton
                        href={candidateDetails?.personalEmail ? `mailto:${candidateDetails?.personalEmail}` : ''}
                        aria-label="Email"
                      >
                        <EmailIcon sx={{ color: '#C93D94' }} />
                      </IconButton>
                    </Tooltip>

                    <IconButton
                      href={getSingleEntityUrl(candidateDetails?.id)}
                      target="_blank"
                      aria-label="Candidate Link"
                      sx={{ marginLeft: 'auto' }}
                    >
                      <Avatar src="" alt="Candidate Link" sx={{ width: '22px', height: '22px' }} />
                    </IconButton>
                  </Box>
                  <Divider sx={{ mb: 1 }} />
                  {candidateDetails?.notes[0] && (
                    <Typography variant="subtitle2" color="text.secondary">
                      <Box>
                        <ShowDate
                          dateUTC={candidateDetails?.notes[0]?.createdAt || ''}
                          format={DATE_FORMAT.FULL}
                          withCommaAtEnd
                        />
                        <TimelineRecordAuthor
                          id={candidateDetails?.notes[0]?.createdById || ''}
                          name={candidateDetails?.notes[0]?.createdByName || ''}
                        />
                      </Box>
                    </Typography>
                  )}
                </Box>
              </Box>
              {candidateDetails?.notes[0] && (
                <NotesField
                  noteText={candidateDetails?.notes[0].noteText}
                  href={getSingleEntityUrl(candidateDetails?.id)}
                />
              )}
            </Box>
            {candidateDetails?.notes[1] && (
              <>
                <Divider sx={{ mb: 1 }} />
                <CardContent
                  sx={{
                    p: 0,
                    '&:last-child': {
                      p: 0,
                    },
                  }}
                >
                  <Typography variant="subtitle2" color="text.secondary">
                    <Box>
                      <ShowDate
                        dateUTC={candidateDetails?.notes[1]?.createdAt || ''}
                        format={DATE_FORMAT.FULL}
                        withCommaAtEnd
                      />
                      <TimelineRecordAuthor
                        id={candidateDetails?.notes[1]?.createdById || ''}
                        name={candidateDetails?.notes[1]?.createdByName || ''}
                      />
                    </Box>
                  </Typography>
                  <NotesField
                    noteText={candidateDetails?.notes[1].noteText}
                    href={getSingleEntityUrl(candidateDetails?.id)}
                  />
                </CardContent>
              </>
            )}

            {currentStage === Stages.Screening || currentStage === Stages.Interview ? (
              <>
                <Divider sx={{ mb: 1 }} />
                <CopyInterviewSchedulerLink
                  personalEmail={candidateDetails?.personalEmail}
                  currentApplicationId={currentApplicationId}
                />
              </>
            ) : (
              <></>
            )}
          </Card>
        )}
      </Box>
    </Popover>
  );
};

export default CandidateMenu;

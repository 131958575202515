import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import Show from 'shared/uibuilder/show/Show';
import useMessageService, { SuccessMessage, ErrorMessage } from 'shared/message/messageService';
import TabPanel from './TabPanel';
import ChangeRequestsContent from './ChangeRequestsContent';
import { ChangeRequestsListProps, ChangeRequestStatus } from './types';
import Loading from 'shared/uibuilder/Loading';

/**
 * Component for displaying and managing change requests for a vacancy
 */
const ChangeRequestsList: React.FC<ChangeRequestsListProps> = ({ vacancyId, refreshData }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isResolving, setIsResolving] = useState(false);
  const { searchVacancyChangeRequests, resolveVacancyChangeRequest } = useVacancyGroupService();
  const { addMessage } = useMessageService();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const fetchChangeRequests = async (status: ChangeRequestStatus) => {
    try {
      const data = await searchVacancyChangeRequests(vacancyId, status);
      return data;
    } catch (error) {
      addMessage(new ErrorMessage('Failed to fetch change requests'));
      return [];
    }
  };

  const handleSolveChangeRequest = async (requestId: string) => {
    setIsResolving(true);
    try {
      await resolveVacancyChangeRequest(vacancyId, requestId);
      addMessage(new SuccessMessage('Change request has been successfully resolved.'));
      await refreshData();
    } catch (error) {
      addMessage(new ErrorMessage('Failed to resolve change request.'));
    } finally {
      setIsResolving(false);
    }
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {isResolving && <Loading hasBackground />}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="change requests tabs"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: theme => theme.palette.primary.main,
            },
          }}
        >
          <Tab
            label="Pending"
            id="change-requests-tab-0"
            aria-controls="change-requests-tabpanel-0"
            sx={{
              '&.Mui-selected': {
                color: theme => theme.palette.primary.main,
              },
            }}
          />
          <Tab
            label="Resolved"
            id="change-requests-tab-1"
            aria-controls="change-requests-tabpanel-1"
            sx={{
              '&.Mui-selected': {
                color: theme => theme.palette.primary.main,
              },
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <Show getDataMethod={() => fetchChangeRequests('ACTIVE')}>
          <ChangeRequestsContent handleSolveChangeRequest={handleSolveChangeRequest} />
        </Show>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Show getDataMethod={() => fetchChangeRequests('RESOLVED')}>
          <ChangeRequestsContent handleSolveChangeRequest={handleSolveChangeRequest} />
        </Show>
      </TabPanel>
    </Box>
  );
};

export default ChangeRequestsList;

/* istanbul ignore file */
import React from 'react';
import RunMigrationButton from 'financialAnalytic/shared/button/RunMigrationButton';
import usePurchaseOrderService, { PERFORM_MIGRATION } from '../../usePurchaseOrderService';

const LOCAL_STORAGE_KEY = 'purchase-export-data';

const RunPurchaseOrderValidationButton = () => {
  const { runPurchaseOrderValidation } = usePurchaseOrderService();

  return (
    <RunMigrationButton
      displayMessage="Please, Fill out inputs below"
      localStorageKey={LOCAL_STORAGE_KEY}
      submitMethod={runPurchaseOrderValidation}
      permissionToCheck={PERFORM_MIGRATION}
    >
      Run Validation
    </RunMigrationButton>
  );
};

export default RunPurchaseOrderValidationButton;

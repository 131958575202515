import React from 'react';
import style from './CandidateInterviewSteps.module.scss';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { Stages } from 'erp/recruitment/recruitingBoard/constants';

interface CandidateInterviewStepsProps {
  activeStep: Stages;
}

const CandidateInterviewSteps: React.FC<CandidateInterviewStepsProps> = ({ activeStep }) => {
  const nonIncludeSteps = ['PendingRejection', 'Closed', 'Rejected'];
  const steps = Object.values(Stages).filter(stage => !nonIncludeSteps.includes(stage));
  const activeStepIndex = activeStep ? steps.indexOf(activeStep) : -1;
  return (
    <Stack sx={{ width: '100%' }} spacing={0}>
      <Stepper alternativeLabel activeStep={activeStepIndex} connector={null}>
        {steps.map((label, index) => (
          <Step key={label} sx={{ padding: '1px' }}>
            <div
              className={`
             ${style.stageStep}
             ${index === activeStepIndex ? style.stageStep_active : ''}
             ${index <= activeStepIndex ? style.stageStep_past : style.stageStep_inactive}
             `}
            >
              {label}
            </div>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default CandidateInterviewSteps;

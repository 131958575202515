import React from 'react';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import AssingEmployeeButton from '../../buttons/AssingEmployee/AssingEmployeeButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { IconButton } from 'uibuilder/button';
import MenuItem from '@mui/material/MenuItem';
import Box from 'uibuilder/Box';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { DashboardEmployee } from '../../../types';
import useEmployeesDashboardService from '../../../useEmployeesDashboardService';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAsyncValue from 'shared/asyncHelper';
import useEmployeesForAssingService from './useEmployeesForAssingService';

const AssingMenu = () => {
  const { addMessage } = useMessageService();
  const { getUserAlias } = useAuthenticatedUserService();
  const { assigneEmployeeAction } = useEmployeesDashboardService();
  const { employees } = useEmployeesForAssingService();
  const { getValue } = useFieldHelper({ source: 'retentionRiskAssigneeAlias' });

  const assignAlias = getValue();
  const { data, setData } = useShowContext();
  const currentUserAlias = useAsyncValue(() => getUserAlias());
  const { retentionRiskId: riskId } = data.getData() as DashboardEmployee;

  const assignCallback = async (employeeAlias: string) => {
    try {
      await assigneEmployeeAction(riskId, { assigneeAlias: employeeAlias });
      setData!({
        ...data.getData(),
        retentionRiskAssigneeAlias: employeeAlias,
      });
    } catch {
      addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
    }
  };

  return (
    <DropdownMenu
      menuPlacement="bottom-end"
      popupId="AssingMenu"
      sxList={{
        maxHeight: '150px',
        overflow: 'auto',
        p: '6px 8px',
      }}
      disablePortal={false}
      iconButton={
        <IconButton sx={{ p: 0 }}>
          <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      }
    >
      {[
        {
          name: 'Assign to me',
          alias: currentUserAlias!,
        },
        ...employees.filter(item => item.alias !== currentUserAlias),
      ].map(item => {
        return (
          <MenuItem
            key={item.alias}
            disabled={assignAlias === item.alias}
            onClick={() => {
              assignCallback(item.alias);
            }}
            sx={{
              fontSize: '12px',
              p: '7px !important',
              borderBottom: '1px solid var(--mui-palette-TableCell-border)',
            }}
          >
            {item.name}
          </MenuItem>
        );
      })}
    </DropdownMenu>
  );
};

const AssingEmployee = () => {
  const { getValue } = useFieldHelper({ source: 'retentionRiskAssigneeAlias' });
  const assignAlias = getValue();

  return (
    <>
      {assignAlias ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <EmployeeLinkFieldWithNameLoading externalId={assignAlias} />
          <AssingMenu />
        </Box>
      ) : (
        <AssingEmployeeButton />
      )}
    </>
  );
};

export default AssingEmployee;

/* istanbul ignore file */
import React from 'react';
import { PURCHASE_ORDER_STATUSES_OPTIONS } from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreatePurchaseOrderButton from 'financialAnalytic/purchaseOrder/shared/button/CreatePurchaseOrderButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import PurchaseOrderLink from 'financialAnalytic/purchaseOrder/shared/field/PurchaseOrderLink';
import ViewPurchaseOrderButton from 'financialAnalytic/purchaseOrder/shared/button/ViewPurchaseOrderButton';
import EnumField from 'shared/uibuilder/field/EnumField';
import TextField from 'shared/uibuilder/field/TextField';
import SearchResultField from 'shared/uibuilder/field/SearchResultField';
import DateField from 'shared/uibuilder/field/DateField';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import PurchaseOrderStatusFilter from 'financialAnalytic/purchaseOrder/shared/filter/PurchaseOrderStatusFilter';
import PurchaseOrderTypeFilter from 'financialAnalytic/purchaseOrder/shared/filter/PurchaseOrderTypeFilter';
import ListPageHeaderWrapper from 'financialAnalytic/shared/ListPageHeaderWrapper';
import PurchaseOrderTotalField from 'financialAnalytic/purchaseOrder/shared/field/PurchaseOrderTotalField';
import PurchaseOrderTypeField from 'financialAnalytic/purchaseOrder/shared/field/PurchaseOrderTypeField';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import OfficeFilter from '../../shared/filter/OfficeFilter';
import RunInvoiceMigrationButton from '../shared/button/RunPurchaseOrderMigrationButton';
import RunPurchaseOrderValidationButton from '../shared/button/RunPurchaseOrderValidationButton';

const PurchaseOrderListPageContent = ({
  isSensitiveDataIsShown,
  toggleSensitiveDataButton,
}: {
  isSensitiveDataIsShown?: boolean;
  toggleSensitiveDataButton?: Nullable<React.ReactElement>;
}) => {
  const filters = (
    <Filters>
      <FiltersLayout>
        <PurchaseOrderStatusFilter label="Status" source="status" />
        <PurchaseOrderTypeFilter label="Type" source="type" />
        <OfficeFilter source="office" label="Office" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <ListPageLayout
      header={
        <ListPageHeaderWrapper header={<ListHeader label="Purchase Orders" />} actions={[toggleSensitiveDataButton]} />
      }
      actions={[<RunInvoiceMigrationButton />, <RunPurchaseOrderValidationButton />, <CreatePurchaseOrderButton />]}
      filter={filters}
      search={<SearchInput label="Title" />}
    >
      <DataGridLayout buttons={[<ViewPurchaseOrderButton source="id" />]}>
        {
          [
            <PurchaseOrderLink source="id" nameSource="id" label="Purchase Order ID" width="15%" />,
            <PurchaseOrderTypeField source="type" label="Type" width="95px" />,
            <SearchResultField source="title" label="Title" width="20%" />,
            <TextField source="vendor" label="Vendor" width="20%" />,
            isSensitiveDataIsShown ? <PurchaseOrderTotalField label="Total" source="subTotal" width="17%" /> : null,
            <EnumField label="Status" source="status" options={PURCHASE_ORDER_STATUSES_OPTIONS} width="95px" />,
            <DateField source="requiredDeliveryDate" label="Required Delivery Date" width="150px" />,
            <DateField source="expirationDate" label="Expiration Date" width="120px" />,
            <TextField source="purchasePurpose" label="Purchase Purpose" width="25%" />,
          ].filter(Boolean) as React.ReactElement[]
        }
      </DataGridLayout>
    </ListPageLayout>
  );
};

export default PurchaseOrderListPageContent;

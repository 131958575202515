import React from 'react';
import Stack from '@mui/material/Stack';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import Typography from 'uibuilder/Typography';
import { RetentionRiskIssue } from '../../types';

const IssuesEmployeeDashboardFiled = () => {
  const { getValue } = useFieldHelper<RetentionRiskIssue[]>({ source: 'retentionRiskIssues' });
  const issues = getValue();

  return (
    <Stack>
      <Typography variant="body2" color="error">
        {issues[0]?.description || ''}
      </Typography>
      {issues.length > 1 && (
        <Typography sx={{ fontSize: '10px' }} color="error">{`...and ${issues.length - 1} more`}</Typography>
      )}
    </Stack>
  );
};

export default IssuesEmployeeDashboardFiled;

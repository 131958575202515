/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { ListField, TextField } from 'shared/uibuilder/field';
import { EmployeeLinkFieldWithNameLoading } from 'erp/employee';

const EmployeePeopleManagersField = (props: any) => {
  const { data: showData } = useShowContext();
  const assignedPeopleManagerAliases = showData?.getValueBySource('assignedPeopleManagerAliases') || [];
  const isAllPeopleManagersAssigned = assignedPeopleManagerAliases?.length === 0;

  return isAllPeopleManagersAssigned ? (
    <TextField value="All People Managers" label="People Managers" />
  ) : (
    <ListField source="assignedPeopleManagerAliases" label="People Managers">
      <EmployeeLinkFieldWithNameLoading />
    </ListField>
  );
};

export default EmployeePeopleManagersField;

import React, { useMemo } from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import style from './CandidateStepsStatuses.module.scss';
import { stepConfigurations } from 'erp/recruitment/recruitingBoard/constants';
import Stack from '@mui/material/Stack';
import IconMenu from 'erp/recruitment/recruitingBoard/shared/IconMenu';
import useRecruitingBoardService from 'erp/recruitment/recruitingBoard/useRecruitingBoardService';

const CandidateStepsStatuses = () => {
  const { data } = useShowContext();
  const { completeJobStep } = useRecruitingBoardService();

  const appInfo = useMemo(() => {
    if (!data.lastActiveApplication) {
      return null;
    }

    const application = data.lastActiveApplication;
    const pipelineState = application.applicationPipelineState;

    return {
      id: application.id,
      version: application.version,
      stage: pipelineState.stage,
      activeStates: pipelineState.jobsStates || [],
      tooltipData: pipelineState.info || [],
    };
  }, [data.lastActiveApplication]);

  if (appInfo === null || !Object.prototype.hasOwnProperty.call(appInfo, 'stage')) {
    return '';
  }

  return (
    <Stack direction="column" gap="2px">
      {stepConfigurations[appInfo.stage]?.map(config => (
        <Stack direction="row" gap="2px" key={config.job}>
          <Stack direction="row" gap="5px" className={style.candidateIconParent}>
            <IconMenu
              key={config.job}
              icon={config?.icon}
              items={config?.states}
              tooltipData={appInfo.tooltipData}
              defaultKey={
                config?.states?.find(state => appInfo.activeStates.includes(state.key))?.key || config.states[0].key
              }
              onChange={(key, payload) => {
                const event = config.states.find(state => state.key === key)?.event;
                if (event) {
                  completeJobStep(appInfo.id, appInfo.version, config.job, event, payload);
                }
              }}
            />
            {config?.states?.find(state => appInfo.activeStates.includes(state.key))?.text || config.states[0].text}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default CandidateStepsStatuses;

/* istanbul ignore file */
import React from 'react';
import EmployeeDropdown from 'shared/uibuilder/form/input/EmployeeDropdown';

const PeopleManagerDropdown = (props: any) => {
  const filter = {
    'specification:peopleManagerSpecification': {
      eq: true,
    },
  };

  return <EmployeeDropdown filter={filter} {...props} />;
};

export default PeopleManagerDropdown;

// services
import useRoute from 'shared/routing/useRoute';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { useKernelApi } from 'api';
import useServiceService from 'erp/service/shared/serviceService';
import useEmployeeService from 'erp/employee/employeeService';
// validation
import EMPLOYEE_DAVINCI_ROLES_VALIDATION from 'erp/employee/companyRoles/create/rolesValidation';

type SearchParams = {
  pageNumber: number;
  sort?: any;
  employeeAlias?: Nullable<string>;
  pageSize: number;
};

// permissions
export const READ_EMPLOYEE_COMPANY_ROLES = 'READ_EMPLOYEE_COMPANY_ROLES';
export const UPDATE_EMPLOYEE_COMPANY_ROLES = 'UPDATE_EMPLOYEE_COMPANY_ROLES';

// roles
export const SERVICE_ENGINEER_POSTFIX = '_SE';
export const SERVICE_MANAGER_POSTFIX = '_SM';

export const enum Role {
  // C level
  CEO = 'CEO',
  CFO = 'CFO',
  CTO = 'CTO',
  COO = 'COO',

  // Employee roles
  EMPLOYEE = 'EMPLOYEE',

  // Managers
  EDUCATION_MANAGER = 'EDUCATION_MANAGER',
  HR_MANAGER = 'HR_MANAGER',

  // Others
  LEAD_RECRUITER = 'LEAD_RECRUITER',
  VP_OF_SALES = 'VP_OF_SALES',
  MARKETER = 'MARKETER',
  RECRUITER = 'RECRUITER',
  PRESALE_COORDINATOR = 'PRESALE_COORDINATOR',
  MARKETING_MANAGER = 'MARKETING_MANAGER',
  PEOPLE_MANAGER = 'PEOPLE_MANAGER',

  // Service managers
  ASSIGNMENT_MANAGEMENT_SM = 'ASSIGNMENT_MANAGEMENT_SM',
  EDUCATION_SM = 'EDUCATION_SM',
  EMPLOYEE_MANAGEMENT_SM = 'EMPLOYEE_MANAGEMENT_SM',
  FINANCE_SM = 'FINANCE_SM',
  IDENTITY_MANAGEMENT_SM = 'IDENTITY_MANAGEMENT_SM',
  IT_SM = 'IT_SM',
  LEGAL_SM = 'LEGAL_SM',
  OFFICE_MANAGEMENT_SM = 'OFFICE_MANAGEMENT_SM',
  PROCESS_SUPPORT_SM = 'PROCESS_SUPPORT_SM',
  PROJECT_MANAGEMENT_OFFICE_SM = 'PROJECT_MANAGEMENT_OFFICE_SM',
  RESOURCE_MANAGEMENT_SM = 'RESOURCE_MANAGEMENT_SM',
  SECURITY_SM = 'SECURITY_SM',

  // Service engineers
  ASSIGNMENT_MANAGEMENT_SE = 'ASSIGNMENT_MANAGEMENT_SE',
  EDUCATION_SE = 'EDUCATION_SE',
  EMPLOYEE_MANAGEMENT_SE = 'EMPLOYEE_MANAGEMENT_SE',
  FINANCE_SE = 'FINANCE_SE',
  IDENTITY_MANAGEMENT_SE = 'IDENTITY_MANAGEMENT_SE',
  IT_SE = 'IT_SE',
  LEGAL_SE = 'LEGAL_SE',
  OFFICE_MANAGEMENT_SE = 'OFFICE_MANAGEMENT_SE',
  PROCESS_SUPPORT_SE = 'PROCESS_SUPPORT_SE',
  PROJECT_MANAGEMENT_OFFICE_SE = 'PROJECT_MANAGEMENT_OFFICE_SE',
  RESOURCE_MANAGEMENT_SE = 'RESOURCE_MANAGEMENT_SE',
  SECURITY_SE = 'SECURITY_SE',
}

// urls
export const COMPANY_ROLES_PATH = '/companies/roles';
export const ROLE_TO_PATH = {
  [Role.CEO]: Role.CEO.toLowerCase(),
  [Role.CFO]: Role.CFO.toLowerCase(),
  [Role.CTO]: Role.CTO.toLowerCase(),
  [Role.COO]: Role.COO.toLowerCase(),
  [Role.LEAD_RECRUITER]: 'leadrecruiter',
  [Role.VP_OF_SALES]: 'vpofsales',
  [Role.MARKETER]: Role.MARKETER.toLowerCase(),
  [Role.HR_MANAGER]: 'hrmanagers',
  [Role.EMPLOYEE]: 'employees',
  [Role.RECRUITER]: 'recruiters',
  [Role.EDUCATION_MANAGER]: 'educationmanagers',
  [Role.PRESALE_COORDINATOR]: 'presale-coordinators',
  [Role.MARKETING_MANAGER]: 'marketing-managers',
  [Role.PEOPLE_MANAGER]: 'people-managers',
};

// dtos
export const ROLE_TO_DTO_FIELD = {
  [Role.CEO]: 'ceoId',
  [Role.CFO]: 'cfoId',
  [Role.CTO]: 'ctoId',
  [Role.COO]: 'cooId',
  [Role.LEAD_RECRUITER]: 'leadRecruiterId',
  [Role.VP_OF_SALES]: 'vpOfSalesId',
  [Role.MARKETER]: 'marketerId',
  [Role.HR_MANAGER]: 'hrManagersIds',
  [Role.EMPLOYEE]: 'employeesIds',
  [Role.RECRUITER]: 'recruitersIds',
  [Role.EDUCATION_MANAGER]: 'educationManagersIds',
  [Role.PRESALE_COORDINATOR]: 'presaleCoordinatorsIds',
  [Role.MARKETING_MANAGER]: 'marketingManagersIds',
  [Role.PEOPLE_MANAGER]: 'peopleManagersIds',
};

// buttons
export const ROLES_ALLOWED_TO_REMOVE = new Set([
  Role.HR_MANAGER,
  Role.EMPLOYEE,
  Role.RECRUITER,
  Role.EDUCATION_MANAGER,
  Role.PRESALE_COORDINATOR,
  Role.MARKETING_MANAGER,
  Role.PEOPLE_MANAGER,
]);

export const useCompanyRolesRoute = () => {
  return useRoute({ listRoute: `/roles` });
};

export const useCompanyRolesUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();

  return useEntityUrl({ baseLink: `${getSingleEntityUrl()}/roles` });
};

export const useCompanyRolesService = () => {
  const { sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } = useKernelApi();
  const { isServiceRole, addServiceRoleToEmployee, removeServiceRoleFromEmployee } = useServiceService();
  const { getEmployeeCompanyId } = useEmployeeService();

  const currentAlias = useEmployeeId();

  const mapToSearchResponse = (roles: any[]) => ({
    totalElements: roles.length,
    result: roles,
    totalPages: 1,
    pageNumber: 1,
  });

  const searchRolesByAlias = async ({ employeeAlias = currentAlias }: SearchParams) => {
    const response = await sendGetRequest(`/employees/${employeeAlias}/roles`);
    const companyRoleNames: string[] = await response.json();

    const roles = companyRoleNames.map(role => ({ name: role }));

    return mapToSearchResponse(roles);
  };

  const searchAll = async () => {
    const response = await sendGetRequest(COMPANY_ROLES_PATH);
    const companyRoleNames: string[] = await response.json();

    return mapToSearchResponse(companyRoleNames);
  };

  const getUpdateEmployeeRolesDto = (role: Role) => {
    let dto;

    switch (role) {
      case Role.CEO:
      case Role.CTO:
      case Role.COO:
      case Role.CFO:
      case Role.VP_OF_SALES:
      case Role.MARKETER:
      case Role.LEAD_RECRUITER:
        dto = { [ROLE_TO_DTO_FIELD[role]]: currentAlias };
        break;

      case Role.HR_MANAGER:
      case Role.EMPLOYEE:
      case Role.RECRUITER:
      case Role.EDUCATION_MANAGER:
      case Role.PRESALE_COORDINATOR:
      case Role.MARKETING_MANAGER:
      case Role.PEOPLE_MANAGER:
        dto = { [ROLE_TO_DTO_FIELD[role]]: [currentAlias] };
        break;

      default:
        dto = null;
    }

    return dto;
  };

  const sendUpdateEmployeeRolesRequest = async (url: string, dto: any, role: Role) => {
    let response;

    switch (role) {
      case Role.CEO:
      case Role.CFO:
      case Role.CTO:
      case Role.COO:
      case Role.LEAD_RECRUITER:
      case Role.VP_OF_SALES:
      case Role.MARKETER:
        response = await sendPutRequest(url, dto);
        break;

      case Role.HR_MANAGER:
      case Role.EMPLOYEE:
      case Role.RECRUITER:
      case Role.EDUCATION_MANAGER:
      case Role.PRESALE_COORDINATOR:
      case Role.MARKETING_MANAGER:
      case Role.PEOPLE_MANAGER:
        response = await sendPostRequest(url, dto);
        break;

      default:
        response = Promise.reject(new Error(`Unknown role: '${role}'.`));
    }

    return response;
  };

  const addRoleToEmployee = async (formData: any) => {
    const role = formData.role as Role;

    if (isServiceRole(role)) {
      return addServiceRoleToEmployee(role, currentAlias);
    }

    const companyId = await getEmployeeCompanyId(currentAlias!);
    const companyRoleUrl = `/companies/${companyId}/${ROLE_TO_PATH[role as Role.CTO]}`;
    const updateDto = getUpdateEmployeeRolesDto(role);

    return sendUpdateEmployeeRolesRequest(companyRoleUrl, updateDto, role);
  };

  const removeRoleFromEmployee = async (role: Role) => {
    const companyId = await getEmployeeCompanyId(currentAlias!);

    if (isServiceRole(role)) {
      return removeServiceRoleFromEmployee(role, currentAlias);
    }

    const removeRoleUrl = `/companies/${companyId}/${ROLE_TO_PATH[role as Role.CTO]}/${currentAlias}`;

    return sendDeleteRequest(removeRoleUrl);
  };

  const getValidationSchema = async () => EMPLOYEE_DAVINCI_ROLES_VALIDATION;

  return {
    sendUpdateEmployeeRolesRequest,
    getUpdateEmployeeRolesDto,
    getValidationSchema,
    addRoleToEmployee,
    removeRoleFromEmployee,
    searchRolesByAlias,
    searchAll,
  };
};
